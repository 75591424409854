import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Observable, Subject } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { AppConstants } from '../app.constants'


@Injectable({
  providedIn: 'root',
})
export class ChatboxHttpService implements OnDestroy {

  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>()
  private cache = {}
  private cacheProperty(key: string, prop: string, httpCall$: Observable<any>): Observable<any> {
    if (!(key in this.cache && prop in this.cache[key])) {
      const clearCache = new Subject<void>()
      const cacheProp$ = httpCall$.pipe(shareReplay(1))
      this.cache[key] = Object.assign(!!this.cache[key] ? this.cache[key] : {}, { [prop]: { call$: cacheProp$, clear: clearCache } })
    }
    return this.cache[key][prop].call$
  }

  public clearPropCache(key: string, prop: string) {
    this.cache[key][prop].clear.next()
  }

  getUserByIdCached( id ) {
    return this.cacheProperty( id, 'userExtra', this.getUserById(id) )
  }

  getUserById(id) {
    return this.http.get(`${AppConstants.SERVER_API_URL}/api/user-info?userExtraId=${id}`, { observe: 'body' })
  }

  sendModeratorMessage(chatId: string, content) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/chat/${chatId}/messages?type=TEXT&content=${content}`, {},
      )
  }

  fetchModeratorMessagesByChat(id: string, participantsPage = 0, participantsSize = 20, messagesPage = 0, messagesSize = 20) {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/chat/${id}?participants_page=${participantsPage
      }&participants_size=${participantsSize
      }&messages_page=${messagesPage
      }&messages_size=${messagesSize
      }&sort=creationTime,desc`)
  }

  fetchModeratorChatsList() {
    return this.http.get(`${AppConstants.SERVER_API_URL}/api/chat/my-chats`)
  }

  sendMessage( { content, tournamentId, userExtraId, type = 10} ) {
    return this.http.post(`${AppConstants.SERVER_API_URL}/api/support-messages`, { content, tournamentId, userExtraId, type })
  }

  subscribeToUserStatus(userid: number): Observable<unknown> {
    return this.db.object(`users/${userid}/active`).valueChanges()
  }

  directData(tournamentId: number ): Observable<unknown> {
    return this.db.object(`tournaments_extra/${tournamentId}/support_messages`).valueChanges()
  }

  sendModerMessage( { content, userExtraId, tournamentId, type = 10} ) {
    console.log('Sending!')
    return this.http.post(`${AppConstants.SERVER_API_URL}/api/moderator-chat`,
     { content, userExtraId, tournamentId, type })
  }


  fetchTmMessages( { tournamentId } ) {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/moderator-tournament-chat/${tournamentId}/messages`,
      { observe: 'body' },
    )
  }

  fetchP2PMessage(matchId) {
    return this.http.get(`${AppConstants.SERVER_API_URL}/api/chat-in-games-by-match-id?matchId=${matchId}`)
  }

  fetchListUserDirectChat(tournamentId): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.SERVER_API_URL}/api/moderator-chat/users/by-tournament?tournamentId=${tournamentId}`)
  }

  sendTmMessage( tournamentId, text ) {
    if (!text) { return }
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-tournament-chat/${tournamentId}?text=${text}`,
      {},
    )
  }

  ngOnDestroy() {
    this.destroy$.next(true)
  }

}
