import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UserService } from '../../../services/user.service'

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss'],
})
export class UserInfoModalComponent implements OnInit {

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<UserInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  userId: any
  userInfo: any
  userIsLoaded = false

  async ngOnInit() {
    this.userId = this.data.userId
    await this.userService.getUserInfo(this.userId).subscribe(userInfo => {
      this.userInfo = userInfo.body
      console.log(this.userInfo);
      this.userIsLoaded = true
    })
  }

  getConsole(gameAccounts) {
    const consoleTypes = {
      1 : 'Playstation',
      2 : 'Xbox',
    }
    if (gameAccounts && gameAccounts.length && consoleTypes.hasOwnProperty(gameAccounts[0].console)) {
      return consoleTypes[gameAccounts[0].console]
    }
    return 'unknown'
  }

  actionFunction() {
    this.closeModal()
  }

  closeModal() {
    this.userIsLoaded = false
    this.dialogRef.close()
  }

}

