import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CategoryService } from 'src/app/services/category.service'
import { TutorialsService } from 'src/app/services/tutorials.service'
import { ConfirmComponent } from '../shared/components/confirm/confirm.component'
import { ICategory } from '../shared/model/catergory.model'
import { ITutorials } from '../shared/model/tutorials.model'
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent implements OnInit {
  show = true
  ListTutorials: any
  totalResult: number
  p = 1
  pageNumber = 0
  pageSize = 5
  categoryList: any = []
  tutorials: ITutorials = {}
  catergoryName: any
  Catergory: ICategory
  searchName = ''
  filterName =''
  categorys: any = []
  showDelete = false
  idDelete: number
  titleDelete: string


  dataLength = 0
  dataPageSize = 5
  dataPageIndex = 0
  dataPageSizeOptions: number[] = [5, 10, 15]

  dataPageEvent: PageEvent

  onPaginatorChange(evt: PageEvent) {
    const { length, pageSize, pageIndex } = evt

    this.dataPageSize = pageSize
    this.dataPageIndex = pageIndex

    this.getListTutorials()
  }


  constructor(
    private tutorialsService: TutorialsService,
    private categorysService: CategoryService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getListTutorials()
    // this.getListCategory();
  }

  private getListTutorials(): void {
    this.tutorialsService.getTutorialList(this.dataPageIndex , this.dataPageSize, this.filterName ).subscribe(
      (result) => {
        if (result && result.body) {
          this.totalResult = +result.headers.get('X-Total-Count')
          this.dataLength = +result.headers.get('X-Total-Count')

          this.ListTutorials = result.body

        } else {
          console.log('Error getListtutorials :::' +result)

        }
      },
    )
  }

  getListCategory() {
    this.categorysService.getListCategory().subscribe(
      (result)=> {
        this.categoryList = result.body
        console.log('tutorials getlisrCategory() ::: '+this.categoryList)

      },
    )
  }
  // getCatergoryNameById(tutorialCategories : any) {
  //   console.log("getCatergoryNameById() :::",tutorialCategories);
  //   tutorialCategories.forEach(element => {
  //     console.log(element.name);
  //     this.categoryList.push({name : element.name})
  //     // return element.name;
  //   });
    // this.categoryList = [];
    //  if(tutorialCategories){
    //   tutorialCategories.forEach(
    //     tutorialCategories => {
    //       console.log("getCatergoryNameById() :::",tutorialCategories);
    //       this.categoryList.push({id: tutorialCategories.id, name: tutorialCategories.name})
    //     }
    //   )
    // }

  // getCatergoryNameById(id: number)  {
  //   // console.log("ID:::",id);

  //   this.categoryList = [];

  //   this.ListTutorials.forEach(element => {
  //     if(element.id == id ){
  //       // console.log("getCatergoryNameById() :::",element.tutorialCategories);
  //       const List = element.tutorialCategories;
  //       if(List){
  //         List.forEach(element => {
  //           this.categoryList.push({id: element.id, name: element.name});
  //         });
  //       }
  //     }
  //     // else{
  //     //   this.categoryList = [];
  //     // }
  //   });




  // getCatergoryNameById(category: Catergory): string{
  //   // console.log('getCatergoryNameById id:::', id);
  //   if(category && this.categoryList){
  //     this.categoryList.forEach(element => {
  //       for (let i = 0; i < id ; i++) {
  //         // console.log("id :::", id);
  //         if(element.id == id){
  //           // console.log("object :::", element.name);
  //           this.catergoryName = element.name;

  //        }
  //       }
  //     });
  //   }
  //   return this.catergoryName;
  // }


  pageChanged(pageNumber: number) {
    if (pageNumber) {
      console.log('tutorial pageChanged :::'+pageNumber)
      this.pageNumber = pageNumber -1
      this.getListTutorials()
    }
  }
  //  deleteTutorial(tutorials: ITutorials){
  //   this.showDelete = true;
  //   this.titleDelete = tutorials.title;
  //   this.idDelete = tutorials.id;
  //   console.log("deleteTutorials() id :::"+tutorials.id);
  //   // this.tutorialsService.deleteTutorials(tutorials.id).subscribe();
  //   // this.ngOnInit();
  // }
  // onDelete(){
  //   this.tutorialsService.deleteTutorials(this.idDelete).subscribe(
  //     result =>{
  //       this.getListTutorials();
  //     }
  //   );
  //   this.showDelete = false;

  // }

  deleteTutorial(tutorial: ITutorials): void {
    console.log('deleteTutorials() tutorials:::', tutorial )
    const dialogRef = this.dialog.open(ConfirmComponent,
      {
        data : {
          title : 'DELETE TUTORIAL',
          content : `Are you sure to delete Tutorials ${tutorial.title} ?`,
        },
        panelClass :'modal-no-padding',
      })
    dialogRef.afterClosed().subscribe(
        (result)=> {
          console.log('dialogRef result:::',result)
          if(result) {
            this.tutorialsService.deleteTutorials(tutorial.id).subscribe(
              result => {
                this.getListTutorials()
              },
            )
          } else {}
        },
      )
  }

  // searchTutorial( searchName:string){
  //   console.log("searchName :::", searchName);
  //   this.ListTutorials.forEach(element => {
  //     console.log("searchTutorial() element:::", element.id);
  //     // for (let i = 0; i < array.length; i++) {
  //     //   const element = array[i];

  //     // }
  //   });
  // }
  onSearch(value: string) {
    this.filterName = value
    this.getListTutorials()}
}
