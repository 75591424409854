import {AfterContentInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {AngularFireDatabase} from '@angular/fire/database'
import {MatDialog} from '@angular/material/dialog'
import {ActivatedRoute, Params} from '@angular/router'
import {Store} from '@ngrx/store'
import * as _ from 'lodash'
import {from, Observable, Subscription} from 'rxjs'
import {AppConstants} from 'src/app/app.constants'
import {AccountService} from 'src/app/auth/account.service'
import {TourMatchService} from 'src/app/services/tour-match.service'
// tslint:disable-next-line:max-line-length
import * as ChatboxActions from '../../actions/chatbox.actions'
import {
  fetchTmMessages,
  markAsReadMessagesInfoByChatId,
  markReadModeratorMessage,
  markReadSharedRoom,
  onModeratorDirectTournamentSetActiveId,
  sendChatInGame,
  sendModeratorDirectTournamentV2,
  sendModeratorMessage,
  setActiveTab,
  setModeratorActiveChat,
  setTournamentId,
  setUserId,
  toggleOpen,
} from '../../actions/chatbox.actions'
import * as AccountSelectors from '../../auth/account.selectors'
import {selectIsHeadOrSuperModerator} from '../../auth/account.selectors'
import {ChatboxEffects} from '../../effects/chatbox.effects'
import {AppState} from '../../reducers'
// tslint:disable-next-line:max-line-length
import * as ChatboxSelectors from '../../selectors/chatbox.selectors'
import {
  getActiveTab,
  getOpen,
  selectActiveModeratorsChatMessages,
  selectActiveModeratorsDirectTournamentChat,
  selectActiveModeratorsDirectTournamentMessages,
  selectHasModeratorsChatUnread,
  selectModeratorDirectActiveChat,
  selectModeratorsDirectList,
  selectTmMessages,
  selectTmMessagesUnread,
} from '../../selectors/chatbox.selectors'
import {ModeratorFindModalComponent} from '../../shared/components/moderator-find/moderator-find-modal/moderator-find-modal.component'
import {
  selectIsBracketsInited,
  selectMatches,
  selectMyRoomsIds,
  selectNavRooms,
  selectNavStages,
} from '../../timeline/timeline-feature.selectors'
import {selectUnreadGlobalChatCount} from '../../unread/store/unread-feature.selectors'
import {ChatboxHttpService} from '../chatbox-http.service'
import {ActiveUser} from '../models/ActiveUser'
import {ChatboxTabs} from '../models/ChatboxTabs.enum'
import {makeSubscriptionToTournamentRooms} from '../../tournament-data/store/tournament-data.actions'
import {NavRoom, NavRoomsState, NavStagesState} from '../../timeline/tournament-matches-filter/tournament-matches-filter.component'
import {toggleNavRoomItem, toggleNavStageItem} from '../../timeline/timeline-feature.actions'
import {WebSocketAPI} from '../../socket/web.socket'
import {map, take} from 'rxjs/operators'


@Component({
  selector: 'app-ajax-chatbox',
  templateUrl: './ajax-chatbox.component.html',
  styleUrls: ['./ajax-chatbox.component.scss'],
})

export class AjaxChatboxComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('gamesChatScroll', { static: false }) gamesChatScroll: ElementRef
  @ViewChild('directChatScroll', { static: false }) directChatScroll: ElementRef
  @ViewChild('moderatorsAllScroll', { static: false }) moderatorsAllScroll: ElementRef
  @ViewChild('moderatorsDirectScroll', { static: false }) moderatorsDirectScroll: ElementRef
  @ViewChild('reportsScroll', { static: false }) reportsScroll: ElementRef

  constructor(
    protected db: AngularFireDatabase,
    private route: ActivatedRoute,
    public accountService: AccountService,
    private store: Store<AppState>,
    private tourMatchService: TourMatchService,
    private chatHttp: ChatboxHttpService,
    private chatboxEffects: ChatboxEffects,
    private dialog: MatDialog,
  ) { }

  open: Observable<boolean>
  activeTab: Observable<ChatboxTabs>

  chatboxTabs = ChatboxTabs


  // tmMessages
  tmMessages: Observable<any>

  moderMessages
  moderActiveChat



  messages$: Subscription
  params$: Subscription

  activeUsers: any[] = []
  activeUsersComplex: ActiveUser[] = []
  activeReportsChat: number
  selectedP2PChat: number

  reportsMesagesState: any
  tournamentId: number

  usersOnline: any[] = []
  userOnlineSubs: any[] = []


  inputReportsChatModel = ''
  isReportsChatInputFocused = false
  objectKeys = Object.keys

  users$: Observable<any> = from(this.activeUsers)
  usersChangeSubscription: Subscription

  isHeadOrSuperModerator
  isHeadOrSuperModeratorSub$


  isInitialized
  currentMatch
  listStages
  listSectors
  tournamentMatchList
  sector
  stage


  ws: WebSocketAPI

  realtimeDataChat
  // chatCount

  inputGamesChatModel = ''

  isGamesChatInputFocused = false

  account
  currentAccountId
  accountUserExtra

  p2pSectorState
  p2pStageState

  moderChatData
  moderChatDataSub$
  moderChatActiveUsers: ActiveUser[]
  activeDirectChatId

  inputDirectChatModel = ''
  isDirectChatInputFocused = false


  p2pMatches
  showGamesChatsFromMyRooms = false
  showDirectChatsFromMyRooms = false

  tmInputModel
  tmInputFocused
  tmInputDirectModel
  tmInputDirectFocused

  isBracketsInited

  moderatorsDirectList
  moderatorsDirectActiveChat
  moderatorsDirectActiveMessages

  moderatorsDirectActiveChatSub$
  moderatorsDirectActiveChatValue

  moderatorsDirectHasUnread

  globalChatUnreadState: Observable<any>

  tournamentsDirectV2$: Observable<any>
  tournamentsDirectV2 = {
    activeId: undefined,
    activeUsers: [],
  }
  tournamentsDirectV2Messages$: Observable<any>
  tournamentsDirectV2Messages

  myRoomsIds$: Observable<[]>

  reportsDataV2$: Observable<any>
  reportsMessagesV2: Observable<any>
  reportsActiveId: Observable<string | undefined>
  reportsActiveIdSub

  compareId = compareId

  tournamentGlobalRoomUnreadCount: Observable<number>


  userIdV2: Observable<string>

  prevDirectChatSubscriptionMatchId = null
  prevDirectChatSubscription = null

  gamesChatScrollSubscription = null
  directChatScrollSubscription = null
  moderatorsAllScrollSubscription = null
  moderatorsDirectScrollSubscription = null
  reportsScrollSubscription = null


  selectStageActive
  selectRoomActive

  navStagesState: Observable<NavStagesState>
  navRoomsState: Observable<NavRoomsState>

  // initTMSocketApi(tournamentId) {

  //   console.log('[WS]: connecting to: ', `/${tournamentId}/match-events`)
  //   this.ws = new WebSocketAPI([
  //     {
  //       path: `/${tournamentId}/moderator-chat/CREATE/`,
  //     },
  //   ])

  //   this.ws._connect()

  //   this.ws.getRecieveWebSocketData().subscribe(message => {

  //     const evt = JSON.parse(message.data.body)
  //     this.store.dispatch(onTmMessage({message: evt}))
  //   })
  // }


  handleSetModeratorActiveChat( id: string | undefined ) {

    if ( id !== this.moderatorsDirectActiveChatValue  ) {

      this.store.dispatch(setModeratorActiveChat({ chatId : id }))
      if ( !!id ) {
        this.store.dispatch( markReadModeratorMessage( { chatId: id }) )
      }

    }

    if (!id) {
      this.store.dispatch(markReadSharedRoom())
    }
  }

  getAnotherParticipant(participants, currentUser) {
    participants = participants || []
    return participants.find(( { id } ) => currentUser.id !== id )
  }

  ngOnInit() {
    this.navStagesState = this.store.select(selectNavStages)
    this.navRoomsState = this.store.select(selectNavRooms)

    this.currentAccountId = this.accountService.getUserId()

    this.myRoomsIds$ = this.store.select(selectMyRoomsIds)
    this.tournamentsDirectV2$ = this.store.select(selectActiveModeratorsDirectTournamentChat)
    this.tournamentsDirectV2Messages$ = this.store.select(selectActiveModeratorsDirectTournamentMessages)

    this.reportsDataV2$ = this.store.select(ChatboxSelectors.selectReportsByActiveTournament)
    this.reportsActiveId = this.store.select(ChatboxSelectors.selectReportsActiveId)
    this.reportsMessagesV2 = this.store.select(ChatboxSelectors.selectReportsActiveMessages)
    this.reportsActiveIdSub = this.store.select(ChatboxSelectors.selectReportsActiveId).subscribe( id => this.activeReportsChat = id )

    this.globalChatUnreadState = this.store.select(selectUnreadGlobalChatCount)

    this.p2pMatches = this.store.select(selectMatches)
    this.isBracketsInited = this.store.select(selectIsBracketsInited)

    this.tournamentGlobalRoomUnreadCount = this.store.select(selectTmMessagesUnread)

    this.isHeadOrSuperModeratorSub$ = this.store.select(selectIsHeadOrSuperModerator).subscribe(
      value => this.isHeadOrSuperModerator = value,
    )

    this.chatboxEffects.afterTabOpen$.subscribe(
      ({ activeTab, gamesMatch }) => {
        if (activeTab === ChatboxTabs.Games && !!gamesMatch ) {
          this.handleOpenP2PChat(gamesMatch)
          return
        }
        if (activeTab === ChatboxTabs.Direct) {
          this
            .chatHttp
            .fetchListUserDirectChat(this.tournamentId)
            .pipe(map((user: any[]) => {
                return user.map(item => {
                  return {
                    id: item.id,
                    userExtraId: item.userId,
                    unreadCount: 0,
                    moderatorId: this.accountService.getUserId(),
                  }
                })
              },
            )).subscribe(tournamentUser => {
            this.tournamentsDirectV2.activeUsers = tournamentUser
          })

          this
            .tournamentsDirectV2$
            .subscribe(tournamentDirect => {
              console.log('tournamentsDirectV2$',tournamentDirect)
            })
          return
        }
      },
    )

    this.moderatorsDirectList = this.store.select(selectModeratorsDirectList)
    this.moderatorsDirectActiveChat = this.store.select(selectModeratorDirectActiveChat)
    this.moderatorsDirectActiveMessages = this.store.select(selectActiveModeratorsChatMessages)
    this.moderatorsDirectHasUnread = this.store.select(selectHasModeratorsChatUnread)

    this.moderatorsDirectActiveChatSub$ = this.store.select(selectModeratorDirectActiveChat).subscribe(
      value => this.moderatorsDirectActiveChatValue = value,
    )

    this.tmMessages = this.store.select(selectTmMessages)
    this.open = this.store.select(getOpen)
    this.activeTab = this.store.select(getActiveTab)

    this.store.dispatch(setUserId({ userId: this.accountService.getUserId() }))

    this.userIdV2 = this.store.select(AccountSelectors.selectUserId)


    this.params$ = this.route.params.subscribe(({ id: tournamentId = false }: Params) => {
      if (tournamentId) {
        this.store.dispatch(makeSubscriptionToTournamentRooms({tournamentId}))
        this.tournamentId = tournamentId
        // this.initMessagesSub(tournamentId) // TODO: remove me
        this.store.dispatch(setTournamentId({ tournamentId }))
        this.store.dispatch(setActiveTab( { activeTab: ChatboxTabs.TM } ))
        this.store.dispatch(fetchTmMessages({tournamentId}))

        // this.initTMSocketApi(tournamentId)
        // this.initModerChatData(tournamentId)
      }
    })

    this.directChatScrollSubscription = this.tournamentsDirectV2Messages$.subscribe(() => {
      if (this.directChatScroll) {
        setTimeout(() => {
            this.directChatScroll.nativeElement.scrollTop = this.directChatScroll.nativeElement.scrollHeight },
          10)
      }
    })

    this.moderatorsAllScrollSubscription = this.tmMessages.subscribe(() => {
      if (this.moderatorsAllScroll) {
        setTimeout(() => {
            this.moderatorsAllScroll.nativeElement.scrollTop = this.moderatorsAllScroll.nativeElement.scrollHeight },
          10)
      }
    })

    this.moderatorsDirectScrollSubscription = this.moderatorsDirectActiveMessages.subscribe(() => {
      setTimeout(() => {
        if (this.moderatorsDirectScroll) {
          this.moderatorsDirectScroll.nativeElement.scrollTop = this.moderatorsDirectScroll.nativeElement.scrollHeight
        }
      },10)
    })

    this.reportsScrollSubscription = this.reportsMessagesV2.subscribe(() => {
      if (this.reportsScroll) {
        setTimeout(() => {
            this.reportsScroll.nativeElement.scrollTop = this.reportsScroll.nativeElement.scrollHeight },
          10)
      }
    })




  }

  stagesClickHandler(stage: string): void {
    this.store.dispatch(toggleNavStageItem( { value: stage }))
  }

  roomsClickHandler(room: NavRoom) {
    this.store.dispatch(
      toggleNavRoomItem( { value: room.id } ),
    )
  }

  getRoomName(name: string, index: string): string {
    return name.startsWith('[')
      ? name
      : `[${index}] ${name}`
  }

  roomsMouseOutHandler() {
    this.selectRoomActive = false
  }

  stagesMouseOutHandler() {
    this.selectStageActive = false
  }

  // subscribeToUserStatus(userid: number) {

  //   return this.db.object(`users/${userid}/active`).valueChanges().subscribe((data: number) => {
  //     console.log(
  //       `User ${userid} active status is: `, data,
  //     )

  //     const i = _.findIndex(this.activeUsersComplex, (activeUser: ActiveUser) => compareId(activeUser.id, userid))

  //     this.activeUsersComplex[i].onlineStatus = data

  //   })

  // }

  tabClickHandler(activeTab: ChatboxTabs) {
    this.store.dispatch(setActiveTab({ activeTab }))
  }


  getOnlineStatus(id: number) {
    return this.activeUsersComplex.find((user: ActiveUser) => this.compareId(id, user.id) ).onlineStatus
  }

  activeUsersSorted() {
    // tslint:disable-next-line:radix
    return _.sortBy(this.activeUsersComplex, val => parseInt(val.lastMessage))
  }

  // initMessagesSub(id: number) {

    // this.tournamentId = id

    // this.messages$ = this.db.object(`tournaments_extra/${id}/support_messages`).valueChanges()
    //   .pipe(filter(x => !!x === true))
    //   .subscribe((value: RawDirectMessageChain) => {

    //     this.store.dispatch(loadReportsChatSuccess({ data: value, tournamentId: id.toString() }))
    //     this.activeUsers = Object.keys(value)

    //     if (!arraysEqual(this.activeUsers, this.usersOnline)) {

    //       this.usersOnline = this.activeUsers


    //       this.activeUsersComplex = this.activeUsers.map(
    //         userid => ({
    //           id: userid,
    //           onlineStatus: 0,

    //           lastMessage: Object.keys(value[userid]).sort()[Object.keys(value[userid]).length - 1],
    //         }),
    //       )
    //       this.unsubscribeFromOnline()
    //       this.userOnlineSubs = this.usersOnline.map(userid => this.subscribeToUserStatus(userid))

    //       this.store.dispatch(loadUnreadInfo())
    //       console.log('Online list users state changed;')
    //     }

    //     // this.reportsMesagesState = value
    //   })
  // }

  ngAfterContentInit(): void {
    this.isInitialized = true
  }



  // TODO: check if we can remove it
  handleOpenP2PChat(match) {
    const { id } = match

    this.selectedP2PChat = id
    this.currentMatch = match
    this.initGamesChat(id)
  }

  p2pAsArray2(data) {
    const arr = []
    if (data) {
      const keys = Object.keys(data)
      return (keys.map(key => data[key]))
    }
    return arr
  }

  initGamesChat(tourMatchId) {

    this.ws = new WebSocketAPI([
      { path: `/tournament/${this.tournamentId}/match-chat`},
    ])

    this.ws._connect()

    if (this.prevDirectChatSubscription) {
      this.prevDirectChatSubscription.unsubscribe()
    }

    // const link = 'tournaments/' + this.tournamentId + '/' + tourMatchId + '/messages'
    // this.prevDirectChatSubscription = this.db
    //   .object(link)
    //   .valueChanges()
    //   .pipe(take(1))
    //   .subscribe(items => {
    //     console.log('AngularFirebase',items)
    //     this.prevDirectChatSubscriptionMatchId = tourMatchId
    //     if (this.gamesChatScroll) {
    //       setTimeout(() => {
    //           this.gamesChatScroll.nativeElement.scrollTop = this.gamesChatScroll.nativeElement.scrollHeight
    //         }, 10)
    //     }
    //     // this.chatCount = this.realtimeDataChat ? Object.keys(this.realtimeDataChat).length : 0
    //   })

    this.prevDirectChatSubscription = this.chatHttp.fetchP2PMessage(tourMatchId).subscribe(items => {
        this.realtimeDataChat = items
        this.prevDirectChatSubscriptionMatchId = tourMatchId
        if (this.gamesChatScroll) {
          setTimeout(() => {
            this.gamesChatScroll.nativeElement.scrollTop = this.gamesChatScroll.nativeElement.scrollHeight
          }, 10)
        }
      },
    )

    this.ws.getRecieveWebSocketData().subscribe((result) => {
      const bodyString = result.data.body
      const bodyJson = JSON.parse(bodyString)
      console.log('matchChat',result)
      if(bodyJson.eventType === 'matchChat') {
        const nextChat = {
          id : bodyJson.body.id,
          content : bodyJson.body.content,
          type : bodyJson.body.type,
          senderIsModerator : bodyJson.body.senderIsModerator,
          senderNickName : bodyJson.body.senderNickName,
          senderAvatar : bodyJson.body.senderAvatar,
          createDateTime : bodyJson.body.createDateTime,
          senderId : bodyJson.body.senderId,
          tourMatchId : bodyJson.body.tourMatchId,
        }
        this.realtimeDataChat.push(nextChat)
        setTimeout(() => {
          this.gamesChatScroll.nativeElement.scrollTop = this.gamesChatScroll.nativeElement.scrollHeight
        }, 10)
      }
    })
  }


  chatOpenHandler(): void {
    this.store.dispatch(toggleOpen())
  }

  // TODO: replace with global handler
  imageLinkHandler(url: string): string {
    return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
  }

  unsubscribeFromOnline() {
    this.userOnlineSubs.map(sub => sub.unsubscribe())
  }

  ngOnDestroy(): void {
    // TODO: refactor to takeUntil()
    this.unsubscribeFromOnline()
    this.params$.unsubscribe()
    // this.messages$.unsubscribe()
    if (this.prevDirectChatSubscription) {
      this.prevDirectChatSubscription.unsubscribe()
    }

    this.moderatorsAllScrollSubscription.unsubscribe()
    this.moderatorsDirectScrollSubscription.unsubscribe()
    this.directChatScrollSubscription.unsubscribe()
    this.reportsScrollSubscription.unsubscribe()
  }




  /** Chat field handlers */
  // Moderators Chat
  tmInputFocusedHandler(focused: boolean) {
    this.tmInputFocused = focused
  }

  tmSendMessageHandler() {
    this.tmInputModel.trim()
    if (this.tmInputModel === '') { return }

    this.chatHttp.sendTmMessage(this.tournamentId, this.tmInputModel)
      .subscribe(res => {
        console.log('sendTmMessage',res)
      })
    this.tmInputModel = ''
  }

  tmInputDirectFocusedHandler(focused: boolean) {
    this.tmInputDirectFocused = focused
  }

  tmSendDirectMessageHandler() {
    this.tmInputDirectModel.trim()
    if (this.tmInputDirectModel === '') { return }


    this.store.dispatch(sendModeratorMessage( { content: this.tmInputDirectModel } ))
    this.tmInputDirectModel = ''
  }

  // Direct Chat

  onSendDirectChat() {
    this.inputDirectChatModel.trim()
    if (this.inputDirectChatModel.trim().length > 0) {

      const toSend = {
        content: this.inputDirectChatModel,
        activeId: this.activeDirectChatId,
        type: 10,
      }

      this.store.dispatch(sendModeratorDirectTournamentV2( { toSend }))
      // this.chatboxHttp.sendModerMessage(toSend).subscribe()
      this.inputDirectChatModel = ''
    }
  }
  inputDirectChatFocusHandler(val: boolean) {
    this.isDirectChatInputFocused = val
  }
  setActiveDirectChat(id) {
    this.activeDirectChatId = id
    this.tournamentsDirectV2.activeId = id
    this.store.dispatch(onModeratorDirectTournamentSetActiveId({ activeId: id}))
    this.store.dispatch(markAsReadMessagesInfoByChatId({chatId: id}))
    this.initActiveDirectChat(id)
  }


  // Games Chat
  onSendGamesChat() {
    if (this.inputGamesChatModel) {
      this.store.dispatch(sendChatInGame({
        tournamentId: this.tournamentId,
        tourMatchId: this.currentMatch.id,
        content: this.inputGamesChatModel,
        typeNumber: 10,
      }))
      this.inputGamesChatModel = ''
    }
  }

  inputGamesChatFocusHandler(bool) {
    this.isGamesChatInputFocused = bool
  }

  // Reports Chat

  onSendReportsChat() {

    this.inputReportsChatModel.trim()
    if (this.inputReportsChatModel === '') { return }

    const dto = {
      content: this.inputReportsChatModel,
      tournamentId: this.tournamentId,
      userExtraId: this.activeReportsChat,
    }

    this.chatHttp.sendMessage(dto).subscribe()
    this.inputReportsChatModel = ''
  }

  inputReportsChatFocusedHandler(bool) {
    this.isReportsChatInputFocused = bool
  }

  setActiveReportsChat(chatId) {

    // this.activeReportsChat = chatId // remove
    this.store.dispatch(ChatboxActions.reportsSetActiveId({ chatId }))
    this.store.dispatch(ChatboxActions.markAsReadSupportByChatId({ chatId }))

  }

  onFindModeratorModal() {
    const dialogRef = this.dialog.open(
      ModeratorFindModalComponent,
      {
        backdropClass: 'modal-blurred',
        panelClass: 'modal-no-padding',
      },
    )
  }

  private initActiveDirectChat(id) {
    this.ws = new WebSocketAPI([
      // tslint:disable-next-line:max-line-length
      { path: `/tournaments/${this.tournamentId}/users/${this.isHeadOrSuperModerator ? 'head_mod' : this.accountService.getUserId() }/moderator_chat/${id}`},
    ])

    this.ws._connect()

    this.tournamentsDirectV2Messages$.subscribe(msg => {
        if(Array.isArray(msg)) {
          const msgObj = msg.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})
          this.tournamentsDirectV2Messages = (Object.keys(msgObj).length === 0 ? {} : JSON.parse(JSON.stringify(msgObj)))
        } else {
          this.tournamentsDirectV2Messages = (Object.keys(msg).length === 0 ? {} : JSON.parse(JSON.stringify(msg)))
        }
      },
    )
    this.ws.getRecieveWebSocketData().subscribe((result) => {
      const bodyString = result.data.body
      const bodyJson = JSON.parse(bodyString)
      const nextChat = {
        content : bodyJson.body.content,
        createdDateTime : bodyJson.body.createdDateTime,
        id : bodyJson.body.id,
        moderatorId : bodyJson.body.moderatorId,
        moderatorSender : bodyJson.body.moderatorSender,
        senderAvatar : bodyJson.body.senderAvatar,
        senderNickName : bodyJson.body.senderNickName,
        tournamentId : bodyJson.body.tournamentId,
        type : bodyJson.body.type,
        userExtraId : bodyJson.body.userExtraId,
      }
      this.tournamentsDirectV2Messages[bodyJson.body.id] = nextChat
      console.log('this.tournamentsDirectV2Messages',this.tournamentsDirectV2Messages)
    })
  }
}



const compareId = (id1, id2) =>  {
  return id1.toString() === id2.toString()
}





// interface SupportMessagesDTO {
//   [key: number]: {                        // Player id
//     [key: number]: SupportMessageItem,     // Message id
//   }
// }

// interface SupportMessageItem {
//   content: string
//   createdDateTime: string
//   id: number
//   moderatorSender: boolean
//   senderAvatar: string
//   senderNickName: string
//   tournamentId: number
//   type: number
//   userExtraId: number
//   readBy: {
//     [key: number]: boolean,
//   }
// }

const arraysEqual = (arr1: any[], arr2: any[]): boolean => _.isEqual(_.sortBy(arr1), _.sortBy(arr2))

