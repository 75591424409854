import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppConstants } from '../app.constants'
import { AccountService } from 'src/app/auth/account.service'

@Injectable({
  providedIn: 'root',
})
export class RoomsHttpService {

  constructor(
    private http: HttpClient,
    public accountService: AccountService,
  ) { }

  getRoomMatchesById(roomId) {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}/matches`,
      { observe: 'body' },
    )
  }

  getMyRooms(tournamentId) {
    const url =
      `${AppConstants.SERVER_API_URL}/api/moderator-room/my-rooms?withModerators=false&tournamentId=${tournamentId}&withMatches=false`
    return this.http.post(
      url,
      [], { observe: 'body' },
    )
  }

  getAllRooms(tournamentId) {
    let url = `${AppConstants.SERVER_API_URL}/api/moderator-room/my-rooms?withModerators=true&tournamentId=${tournamentId}&withMatches=true`
    if (this.accountService.hasRoleSuperModerator() ||
      this.accountService.hasRoleHeadModerator()) {
      url = `${AppConstants.SERVER_API_URL}/api/moderator-room/all-rooms?withModerators=true&tournamentId=${tournamentId}&withMatches=true`
    }
    return this.http.post(
      url,
      [], { observe: 'body' },
    )
  }

  fetchModerators() {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/all-moderators`, { observe: 'body' },
    )
  }

  assignMatchToRoom({ roomId, match }) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}/matches`,
      match, { observe: 'body' },
    )
  }

  addModeratorToRoom(roomId, moderator: any[]) {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}/moderators`,
      moderator, { observe: 'body' },
    )
  }

  fetchUnassigned( tournamentId, stageIndex) {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/unassigned-matches?stageIndex=${stageIndex}&tournamentId=${tournamentId}`,
      { observe: 'body' },
    )
  }

  fetchStages( { tournamentId } ) {
    return this.http.get(
      `${AppConstants.SERVER_API_URL}/api/bracket/stage-names/${tournamentId}`,
      { observe: 'body' },
    )
  }

  removeModeratorFromRoom(roomId, moderator: any[]) {
    return this.http.request( 'delete',
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}/moderators`,
      {body: moderator},
    )
  }

  unAssignMatchFromRoom({ matchId, roomId }) {
    return this.http.request(
      'delete',
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}/matches`,
      { body: [ matchId ], observe: 'body' },
    )
  }

  createRoom({ name, stageIndex, tournamentId } ) {
      return this.http.post(
        `${AppConstants.SERVER_API_URL}/api/moderator-room/`,
        {name, tournamentId, stageIndex, tourMatches: [], moderators: []},
        { observe: 'body'},
      )
  }

  deleteRoom( { roomId } ) {
    return this.http.delete(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}`,
      { observe: 'body' },
    )
  }

  renameRoom( { roomId, name } ) {
    return this.http.put(
      `${AppConstants.SERVER_API_URL}/api/moderator-room/${roomId}/rename?newName=${name}`,
      { observe: 'body' },
    )
  }
}
