import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { SharedModule } from '../shared/share.module'
// import { ChecklistModule } from '@maarti/mat-checklist';
import { TimelineNotificationItemComponent } from '../timeline-notification-item/timeline-notification-item.component'
import { AjaxTimelineItemComponent } from './ajax-timeline-item/ajax-timeline-item.component'
import { AjaxTimelineComponent } from './ajax-timeline/ajax-timeline.component'
// import { TimelineLayoutComponent } from './timeline-layout/timeline-layout.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component'
import { ParticipantsItemComponent } from './participants-item/participants-item.component'
import { ParticipantsComponent } from './participants/participants.component'
import { TimelineFeatureEffects } from './timeline-feature.effects'
import * as fromTimeline from './timeline-feature.reducer'
import { TournamentMatchesFilterComponent } from './tournament-matches-filter/tournament-matches-filter.component'
import { CountdownModule } from 'ngx-countdown'
import { ModeratorEditModalComponent } from '../moderators/moderator-edit-modal/moderator-edit-modal.component'
import { UserInfoModalComponent } from '../shared/components/user-info-modal/user-info-modal.component'


const routes: Routes = [
  // { path: '', component: AjaxTimelineComponent },
  { path: ':id/timeline', component: AjaxTimelineComponent},
  { path: ':id/participants', component: ParticipantsComponent},
]

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromTimeline.timelineFeatureFeatureKey, fromTimeline.reducer),
    EffectsModule.forFeature([TimelineFeatureEffects]),
    CountdownModule,
    // ChecklistModule,

  ],
  declarations: [
    AjaxTimelineComponent,
    // TimelineLayoutComponent,
    FilterBarComponent,
    AjaxTimelineItemComponent,
    ParticipantsComponent,
    ParticipantsItemComponent,
    TournamentMatchesFilterComponent,
    TimelineNotificationItemComponent,
  ],
})
export class TimelineModule { }
