/* ---DEMO ---*/
export const environment = {
  production: false,
  api: {
    url: 'https://demo-api.newtribe.nl:4444',
    appVersion: '8',
  },
  firebase: {
    apiKey: 'AIzaSyDzGy9XnoddVbMhki6b9oYtpZXktubrpdU',
    authDomain: 'ajax-demo-d934a.firebaseapp.com',
    databaseURL: 'https://ajax-demo-d934a.firebaseio.com',
    projectId: 'ajax-demo-d934a',
    storageBucket: 'ajax-demo-d934a.appspot.com',
    messagingSenderId: '829485486228',
    appId: '1:829485486228:web:6cc3c08e3154a91ab5a481',
    measurementId: 'G-L0YLYBJZTH',
  },
}
