import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as moment from 'moment'
import * as fromTimelineFeature from './timeline-feature.reducer'

export const selectTimelineState = createFeatureSelector<fromTimelineFeature.TimelineState>(
  fromTimelineFeature.timelineFeatureFeatureKey,
)

export const selectTournamentTab = createSelector(
  selectTimelineState,
  ({ activeTab }) => activeTab,
)

export const selectParticipants = createSelector(
  selectTimelineState,
  ({ participants }) => participants,
)

export const selectIsBracketsInited = createSelector(
  selectTimelineState,
 ({ tournamentData }) => tournamentData && tournamentData.initBracketCheck,
)

export const selectNotifications = createSelector(
  selectTimelineState,
  ( { notifications }) => notifications,
)

export const selectNoftificationsCount = createSelector(
  selectTimelineState,
  ({ notifications }) => Object.keys(notifications).length,
)

export const selectTournamentId = createSelector(selectTimelineState, ({ tournamentId }) => tournamentId )
export const selectTournamentStatus = createSelector(selectTimelineState, ({ tournamentData }) => tournamentData.status)
export const selectTournamentPlaying = createSelector(selectTimelineState, ({ tournamentData }) => tournamentData.playing)


export const selectSecsTillTournamentStart = createSelector(
  selectTimelineState,
  ({ tournamentData } ): number | undefined => {

      if (tournamentData.status.toString() !== '0' ) {
        return undefined
      }

      const now = moment(new Date())
      const end = moment(tournamentData.startDateTime)
      return moment.duration(
        end.diff(now),
      ).asSeconds()
  },
)

export const selectTournamentData = createSelector(selectTimelineState, ( { tournamentData } ) => tournamentData || {})

export const selectStages = createSelector(selectTimelineState, ( { stages }) => stages)
export const selectRooms  = createSelector(selectTimelineState, ( { rooms }) => rooms)
export const selectMatches  = createSelector(selectTimelineState, ( { tourMatches }) => tourMatches)

export const selectNavStages = createSelector(selectTimelineState, ( { navStagesState }) => navStagesState)
export const selectNavRooms = createSelector(selectTimelineState, ( { navRoomsState }) => navRoomsState)
export const selectMyRoomsIds = createSelector(selectTimelineState, ( { myRoomsIds }) => myRoomsIds)
