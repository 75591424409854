import { createAction, props } from '@ngrx/store'
import { PaginatorState } from '../../shared/model/paginatorState.model'


export const multipleReportsSubscribed = createAction(
  '[TournamentsData] Multiple sources reports subscribed',
)


export const setOpenedTournamentId = createAction(
  '[TournamentData] Set Opened Tournament Id',
  props<{openedTournamentId: string}>(),
)


export const fetchTournaments = createAction(
  '[TournamentData] Load Tournaments',
)
export const fetchTournamentsSuccess = createAction(
  '[TournamentData] Load Tournaments Success',
  props<{ data: any }>(),
)
export const fetchTournamentsFailure = createAction(
  '[TournamentData] Load Tournaments Failure',
  props<{ error: any }>(),
)
export const makeSubscriptionToTournamentRooms = createAction(
  '[TournamentData] Subscribe to tournament chats',
  props<{ tournamentId: string }>(),
)

export const fetchModerators = createAction(
  '[TournamentData] Load Moderators',
)
export const fetchModeratorsSuccess = createAction(
  '[TournamentData] Load Moderators Success',
  props<{ data: any, length: number }>(),
)
export const fetchModeratorsFailure = createAction(
  '[TournamentData] Load Moderators Failure',
  props<{ error: any }>(),
)

export const setModeratorsSearch = createAction(
  '[TournamentData] Set Moderators search',
  props<{ name: string }>(),
)

export const setModeratorsPaginator = createAction(
  '[TournamentData] Set Moderators Paginator',
  props<{ state: PaginatorState }>(),
)


export const initiateChat = createAction(
  '[TournamentData] Initiate Chat',
  props<{userId: string}>(),
  )
export const initiateChatSuccess = createAction(
    '[TournamentData] Initiate Chat Success',
    props<{userId: string, chatId: string}>(),
)

export const initiateChatFailure = createAction(
  '[TournamentData] Initiate Chat Failure',
  props<{ error: any }>(),
)
