import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { imageAvatarFallback } from '../../../../utils/string.util'

@Component({
  selector: 'app-moderator-find-item',
  templateUrl: './moderator-find-item.component.html',
  styleUrls: ['./moderator-find-item.component.scss'],

})
export class ModeratorFindItemComponent implements OnInit {

  imageAvatarFallback = imageAvatarFallback

  @Input()
  moderator: any

  @Output()
  startChat: EventEmitter<string> = new EventEmitter()

  @Output()
  openChat: EventEmitter<string> = new EventEmitter()

  handleAvatar(link) {
    return this.imageAvatarFallback(link)
  }

  onStartChat() {
    this.startChat.emit(this.moderator.id)
  }

  onOpenChat() {
    this.openChat.emit(this.moderator.id)
  }

  constructor() { }

  ngOnInit() {
  }

}
