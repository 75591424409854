import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AppConstants } from '../app.constants'

@Injectable({ providedIn: 'root' })
export class UserService {

    constructor(protected http: HttpClient) { }

    deactivateUser(email) {
        return this.http.post(`${AppConstants.SERVER_API_URL}/api/users/deactivate/${email}`, {}, { observe: 'body'} )
    }


    getUserList(countries: number[], devices: string[], genders: string[], name: string, page: number,
         size: number, sortBy: string, sortDirection: string, status: string, tournamentId: any = null): Observable<HttpResponse<any>> {

        // {
        //     "countries": [
        //       0
        //     ],
        //     "devices": [
        //       "string"
        //     ],
        //     "genders": [
        //       "MALE"
        //     ],
        //     "name": "string",
        //     "page": 0,
        //     "size": 0,
        //     "sortBy": "",
        //     "sortDirection": "",
        //     "status": "string"
        //   }

        let payload: any = {
            countries,
            devices,
            genders,
            name,
            page,
            size,
            sortBy,
            sortDirection,
            status,
        }

        if (tournamentId) {
            payload.tournamentId = tournamentId
        }

        return this.http.post(AppConstants.SERVER_API_URL + '/api/user-extras/find', payload, { observe: 'response' })
    }

    getCountryList(): Observable<HttpResponse<any>> {
        return this.http.get(AppConstants.SERVER_API_URL + `/api/user-extras/countries`, { observe: 'response' })
    }

    // getFile(): Observable<any> {
    //     const options = {
    //         observe: 'body',
    //         headers: {'Content-Type': 'application/octet-stream'}
    //     }
    //     return this.http.get(AppConstants.SERVER_API_URL + `/api/export/users`, options);
    // }

    getUserInfo(userId): Observable<HttpResponse<any>> {
        return this.http.get(AppConstants.SERVER_API_URL + `/api/user-extras/findByUserId/${userId}`, { observe: 'response' })
    }
}
