import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { UploadImgService } from "src/app/services/upload-img.service";
import { ImageLoaderService } from "./image-loader.service";
import { SafeResourceUrl } from "@angular/platform-browser";
import { finalize } from "rxjs/operators";
declare var Cropper;
@Component({
  selector: "app-image-loader",
  templateUrl: "./image-loader.component.html",
  styleUrls: ["./image-loader.component.scss"],
})
export class ImageLoaderComponent implements OnInit {
  @Input()
  public imgURL: string | ArrayBuffer = undefined;

  @Input()
  public alignCenter = false;

  @Input()
  public label = "Upload image";

  @Input()
  public returnFile = false;

  @Input()
  public deleteLabel = "X";

  @Input() public isCropperEnabled: boolean;

  @Output()
  imageUpload = new EventEmitter<string | ArrayBuffer>();

  @Output()
  fileReturn = new EventEmitter<any>();

  @Output()
  imageRemove = new EventEmitter<void>();
  public loading: boolean;
  public cropper: any;
  public cropperImgUrl: SafeResourceUrl;
  public cropperOptions = {
    maintainAspectRatio: false,
    checkCrossOrigin: true,
  };
  private _file: File;

  constructor(
    private serviceUpload: UploadImgService,
    private _imageLoaderService: ImageLoaderService,
    private _changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  public onSelectFile(event) {
    const { target } = event;
    const { files } = target;
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (!this._isImageFile(mimeType)) {
      return;
    }

    this._file = files[0];
    if (!this.isCropperEnabled) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      // tslint:disable-next-line:variable-name
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        console.log("imgUrl onSelectFile():::", this.imgURL);
        this._onUploadImage(this._file, this._file.name);
      };
    } else {
      this._showCropper(this._file);
    }
  }

  private _isImageFile(mimeType): boolean {
    return !(mimeType.match(/image\/*/) == null);
  }

  public onRemoveImage(): void {
    this.imgURL = null;
    this.imageRemove.next();
  }

  public onCropChosen(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      this.cropper
        .getCroppedCanvas()
        .toBlob(this._blobFallBack.bind(this), "image/jpeg");
    } catch (er) {
      this.loading = false;
      console.log(er);
    }
  }
  /** After crop submit */
  private _blobFallBack(blob: Blob): void {
    if (!blob) {
      return;
    }
    const fileName = this._file ? this._file.name : "cropped image";
    this._onUploadImage(blob, fileName);
  }

  public onCropCancel(): void {
    this._closeCropper();
  }

  public onImageEdit(): void {
    this._showCropper();
  }
  /** On img load event */
  public cropperImgLoaded(e): void {
    const image = document.getElementById("cropper-wrapper__image");
    this.cropper = new Cropper(image, this.cropperOptions);
  }

  private _showCropper(file = null): void {
    if (file) {
      if (typeof Cropper !== "undefined") {
        this.cropperImgUrl = this._imageLoaderService.createSafeUrl(file);
        //  this.currentCropperFile = file;
      } else {
        console.warn(
          'please import cropperjs script and styles to use cropper feature or disable it by setting [enableCropper]="false"'
        );
        return;
      }
    } else {
      const image = document.getElementById("image__inner__preview");
      try {
        this.cropper = new Cropper(image, this.cropperOptions);
      } catch (er) {
        console.log(er);
      }
    }
  }

  private _closeCropper(): void {
    this.cropper.destroy();
    this.cropper = undefined;
    this.cropperImgUrl = undefined;
    this._changeRef.detectChanges();
  }

  private _onUploadImage(file: File | Blob, fileName: string) {
    this.loading = true;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("originalFilename", fileName);
    this.serviceUpload
      .addImage(formData)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((el) => {
        console.log("el =", el);
        this.imgURL = el.avatarUrl;
        this.imageUpload.emit(this.imgURL);
        this._closeCropper();
        if (this.returnFile) {
          this.fileReturn.emit(file);
        }
        //   this.data = el.avatarUrl;
        //   this.passEntry.emit(this.data);
        //  this.activeModal.dismiss('cancel');
      });
  }
}
