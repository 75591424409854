import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { IRule } from "src/app/shared/model/rule.model";
import { RulesService } from "../../../rules/services/rules.service";

@Component({
  selector: "app-dialog-create-rule",
  templateUrl: "./dialog-create-rule.component.html",
  styleUrls: ["./dialog-create-rule.component.scss"],
})
export class DialogCreateRuleComponent implements OnInit {
  ruleId: number;
  rule: IRule;
  editForm: FormGroup = new FormGroup({});
  imgURL = "";
  loadingData = true;
  file = null;

  constructor(
    private formBuilder: FormBuilder,
    public rulesService: RulesService,
    private _changeRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DialogCreateRuleComponent>
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm(rule: any = null) {
    this.loadingData = true;
    this._changeRef.detectChanges();
    if (rule) {
      const {
        titleDefault,
        titlePink,
        description,
        needConfirmation,
        confirmationText,
        orderIndex,
        image,
      } = rule;
      this.imgURL = image.url;
      this.editForm = this.formBuilder.group({
        titleDefault: [titleDefault, Validators.required],
        titlePink: [titlePink, Validators.required],
        description: [description, Validators.required],
        needConfirmation: [needConfirmation, Validators.required],
        confirmationText: [confirmationText, Validators.required],
        orderIndex: [
          orderIndex,
          [Validators.required, Validators.pattern("^[0-9]+$")],
        ],
        imgUrl: [image.url, Validators.required],
      });
    } else {
      this.editForm = this.formBuilder.group({
        titleDefault: [null, Validators.required],
        titlePink: [null, Validators.required],
        description: [null, Validators.required],
        needConfirmation: [false, Validators.required],
        confirmationText: [null, Validators.required],
        orderIndex: [
          null,
          [Validators.required, Validators.pattern("^[0-9]+$")],
        ],
        imgUrl: [null, Validators.required],
      });
    }
    this.loadingData = false;
    this._changeRef.detectChanges();
  }

  onImageLoad(evt) {
    // tslint:disable-next-line:no-string-literal
    console.log(evt);
    this.editForm.controls["imgUrl"].setValue(evt);
    this.imgURL = evt;
    this._changeRef.detectChanges();
  }

  public saveFile(event) {
    this.file = event;
  }

  public save() {
    this.loadingData = true;
    let rule;
    rule = new FormData();
    rule.append("titleDefault", this.editForm.get(["titleDefault"]).value);
    rule.append("titlePink", this.editForm.get(["titlePink"]).value);
    rule.append("description", this.editForm.get(["description"]).value);
    rule.append(
      "confirmationText",
      this.editForm.get(["confirmationText"]).value
    );
    rule.append(
      "needConfirmation",
      this.editForm.get(["needConfirmation"]).value
    );
    rule.append("orderIndex", this.editForm.get(["orderIndex"]).value);

    rule.append("image.file", this.file);
    this.rulesService.postRule(rule).subscribe((res) => {
      this.dialogRef.close(true);
      this.loadingData = false;
    });
  }

  public onRemoveImage(): void {
    this.imgURL = null;
    console.log("image removed");
  }
}
