import { createFeatureSelector, createSelector } from '@ngrx/store'
import { TournamentStatus } from '../models/tournamentStatus.enum'
import * as fromTournamentData from './tournament-data.reducer'

export const selectTournamentDataState = createFeatureSelector<fromTournamentData.TournamentDataState>(
  fromTournamentData.tournamentDataFeatureKey,
)

export const selectOpenedTournament = createSelector(
  selectTournamentDataState,
  ({tournaments, openedTournamentId }) => tournaments[openedTournamentId],
  )

export const selectLiveTournaments = createSelector(
    selectTournamentDataState,
    ({ tournaments }) => {
      const tournamentsLive = {}

      const asArr = Object.values(tournaments).filter(t => t.status === TournamentStatus.Live )
      asArr.map( t => tournamentsLive[t.id] = t)

      return tournamentsLive
    },
  )

export const selectAllTournamentsNames = createSelector(
    selectTournamentDataState,
    ( { tournaments } ) => {
      const toreturn = {}
      for (const t in tournaments) {
        if (tournaments.hasOwnProperty(t)) {
          const element = tournaments[t]
          const { name } = element
          toreturn[element.id] = { name }
        }
      }
      return toreturn
    },
)


export const selectModerators = createSelector(
  selectTournamentDataState,
  ({ moderators }) => moderators,
)

export const selectModeratorsPaginator = createSelector(
    selectTournamentDataState,
    ({ moderatorsPaginator }) => moderatorsPaginator,
)

export const selectModeratorsTotalCount = createSelector(
  selectTournamentDataState,
  ({ moderatorsListLength }) => moderatorsListLength,
)

export const selectModeratorsSearch = createSelector(
  selectTournamentDataState,
  ({ moderatorsListSearch }) => moderatorsListSearch,
)
