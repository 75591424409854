import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filterMyMatches',
})
export class FilterMyMatches implements PipeTransform {

  transform(matches: any, navStagesState: any, navRoomState: any, myRoomsIds: any, showOnlyFromMyRooms = false): any {
    const selectedStages = Object.values(navStagesState).filter((stage: MatchStage)  => stage.checked)
      .map((stage: MatchStage) => stage.value)
    const selectedRooms = Object.values(navRoomState).filter((room: MatchRoom)  => room.checked)
      .map((room: MatchRoom) => room.id)
    const filteredByStages = matches.filter(match => selectedStages.includes(match.stageIndex))
    const filteredByRoom = filteredByStages.filter(match => selectedRooms.includes(match.roomId))
    return showOnlyFromMyRooms ? filteredByRoom.filter(match => myRoomsIds.includes(match.roomId)) : filteredByRoom
  }

}

export interface MatchStage {
  value: string,
  checked: boolean,
}

export interface MatchRoom {
  id: number,
  checked: boolean,
}
