import {
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CategoryService } from "src/app/services/category.service";
import { TutorialsService } from "src/app/services/tutorials.service";
import { ConfirmComponent } from "../../../shared/components/confirm/confirm.component";
import { IRule } from "../../../shared/model/rule.model";
import { PageEvent } from "@angular/material/paginator";
import { RulesService } from "../../../rules/services/rules.service";
import { DialogCreateRuleComponent } from "../dialog-create-rule/dialog-create-rule.component";

@Component({
  selector: "app-dialog-add-rule-tournament",
  templateUrl: "./dialog-add-rule-tournament.component.html",
  styleUrls: ["./dialog-add-rule-tournament.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogAddRuleTournamentComponent implements OnInit {
  loadingData = false;
  show = true;
  rules: any;
  totalResult: number;
  p = 1;
  pageNumber = 0;
  pageSize = 5;
  categoryList: any = [];
  tutorials: IRule = {};
  catergoryName: any;
  Catergory: any;
  searchName = "";
  filterName = "";
  categorys: any = [];
  showDelete = false;
  idDelete: number;
  titleDelete: string;
  tournamentIdsInput = [];
  dataLength = 0;
  dataPageSize = 5;
  dataPageIndex = 0;
  dataPageSizeOptions: number[] = [5, 10, 15];

  dataPageEvent: PageEvent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public rulesService: RulesService,
    public _changeRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DialogAddRuleTournamentComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getRules();
    this.data.tournamentRulesIds.forEach((ruleId) => {
      this.tournamentIdsInput.push(ruleId);
    });
    console.log(this.tournamentIdsInput);
  }

  onPaginatorChange(evt: PageEvent) {
    const { length, pageSize, pageIndex } = evt;
    this.dataPageSize = pageSize;
    this.dataPageIndex = pageIndex;
    this.getRules();
  }

  private getRules(): void {
    console.log(this.dataPageIndex);
    console.log(this.dataPageSize);
    if (!this.filterName) {
      this.rulesService
        .getRules(this.dataPageIndex, this.dataPageSize)
        .subscribe((result) => {
          if (result && result.body) {
            this.totalResult = +result.headers.get("X-Total-Count");
            this.dataLength = +result.headers.get("X-Total-Count");
            this.rules = result.body;
            console.log(this.rules.length);
            this._changeRef.detectChanges();
          } else {
            console.log("Error getRules :::" + result);
          }
        });
    } else {
      this.rulesService
        .getRulesByKeyword(
          this.dataPageIndex,
          this.dataPageSize,
          this.filterName
        )
        .subscribe((result) => {
          if (result && result.body) {
            this.totalResult = +result.headers.get("X-Total-Count");
            this.dataLength = +result.headers.get("X-Total-Count");
            this.rules = result.body;
            this._changeRef.detectChanges();
          } else {
            console.log("Error getRules :::" + result);
          }
        });
    }
  }

  onSearch(value: string) {
    this.filterName = value;
    this.getRules();
  }

  toggleAddingRule(id) {
    if (this.tournamentIdsInput.includes(id)) {
      this.tournamentIdsInput.splice(this.tournamentIdsInput.indexOf(id), 1);
    } else {
      this.tournamentIdsInput.push(id);
    }
    this._changeRef.detectChanges();
  }

  saveChanges() {
    this.dialogRef.close(this.tournamentIdsInput);
  }

  pageChanged(pageNumber: number) {
    if (pageNumber) {
      console.log("tutorial pageChanged :::" + pageNumber);
      this.pageNumber = pageNumber - 1;
      this.getRules();
    }
  }

  createNewRule() {
    let dialogRef = this.dialog.open(DialogCreateRuleComponent, {
      width: "100vw",
      height: "100vh",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getRules();
      }
    });
  }
}
