import { CommonModule } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AjaxTeamComponent } from "src/app/ajax-team/ajax-team.component";
import { AuthGuard } from "src/app/auth/auth.guard";
import { Authorities } from "src/app/auth/models/Authorities.enum";
import { TutorialsModule } from "src/app/tutorials/tutorials.module";
import { AddQuestionModalModule } from "../../quiz/components/add-question-modal/add-question-modal.module";
import { SharedModule } from "../../shared/share.module";
import { CmsnavLayoutComponent } from "./cmsnav-layout.component";

const routes: Routes = [
  {
    path: "",
    component: CmsnavLayoutComponent,
    children: [
      {
        path: "tournaments",
        data: {
          authorities: ["ROLE_MODERATOR"],
        },
        canActivate: [AuthGuard],
        loadChildren:
          "./../../tournaments/tournaments.module#TournamentsModule",
      },
      {
        path: "main-banner",
        data: {
          authorities: [Authorities.Moderator],
        },
        canActivate: [AuthGuard],
        loadChildren: "../../main-banner/main-banner.module#MainBannerModule",
      },
      {
        path: "tutorials",
        data: {
          authorities: ["ROLE_SUPER_MODERATOR"],
        },
        canActivate: [AuthGuard],
        loadChildren: "./../../tutorials/tutorials.module#TutorialsModule",
      },
      {
        path: "faq",
        data: {
          authorities: ["ROLE_SUPER_MODERATOR"],
        },
        canActivate: [AuthGuard],
        loadChildren: "./../../faq/faq.module#FaqModule",
      },
      {
        path: "news",
        data: {
          authorities: ["ROLE_SUPER_MODERATOR"],
        },
        canActivate: [AuthGuard],
        loadChildren: "./../../news/news.module#NewsModule",
      },
      {
        path: "quiz",
        data: {
          authorities: ["ROLE_SUPER_MODERATOR"],
        },
        canActivate: [AuthGuard],
        loadChildren: "./../../quiz/quiz.module#QuizModule",
      },
      {
        path: "ajax-team",
        data: {
          authorities: ["ROLE_SUPER_MODERATOR"],
        },
        canActivate: [AuthGuard],
        component: AjaxTeamComponent,
      },
      {
        path: "rules",
        data: {
          authorities: ["ROLE_MODERATOR"],
        },
        canActivate: [AuthGuard],
        loadChildren: "./../../rules/rules.module#RulesModule",
      },
    ],
  },
];

@NgModule({
  declarations: [CmsnavLayoutComponent, AjaxTeamComponent],
  imports: [
    CommonModule,
    TutorialsModule,
    AddQuestionModalModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [CmsnavLayoutComponent, AjaxTeamComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CmsnavLayoutModule {}
