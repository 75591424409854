import { Injectable, OnDestroy } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import * as SockJS from 'sockjs-client'
import { AppConstants } from 'src/app/app.constants'
import * as Stomp from 'stompjs'
import * as ChatboxActions from '../actions/chatbox.actions'
import * as AccountSelectors from '../auth/account.selectors'
import { ChatboxTabs } from '../chatbox/models/ChatboxTabs.enum'
import { AppState } from '../reducers'
import { RawReportsChain } from '../reducers/chatbox.reducer'
import * as ChatboxSelectors from '../selectors/chatbox.selectors'
import { AjaxSnackComponent, AjaxSnackData } from '../shared/components/ajax-snack/ajax-snack.component'
import * as TournamentDataSelectors from '../tournament-data/store/tournament-data.selectors'
import { loadUnreadInfo } from '../unread/store/unread-feature.actions'
import { connectSuccess, onMessage } from './socket-manager.actions'

@Injectable({
    providedIn: 'root',
})

export class WebSocketClient implements OnDestroy {

    constructor(
        private store: Store<AppState>,
        protected db: AngularFireDatabase,
        private snack: MatSnackBar,
    ) { }


    webSocketEndPoint: string = AppConstants.SERVER_API_URL+'/socket'
    topic: any[]
    stompClient: any
    private recieveDataSubject = new Subject<any>()
    private connected = false


    client: Stomp.Client
    topics: any[]


    destroy$: Subject<boolean> = new Subject<boolean>()

    dmParticipantsSub$
    moderatorsDirectReceivers

    chatInitiationSubscription = null
    directChatWithUserSubscriptions = {}
    sharedModeratorsRoomSubscriptions = {}
    reportsSubscriptions = {}

    initClient() {
        const ws = new SockJS(this.webSocketEndPoint )
        this.client = Stomp.over(ws)
        this.connect()
    }

    connect() {
        this.client.connect({},  () => {
            console.log('[SocketClient]: socket connected')
            this.connected = true
            this.store.dispatch( connectSuccess() )
        } )
    }

    isConnected() {
        return this.connected
    }


    subscribeToDirectChatWithUser(tournamentId, accountId, isHeadOrSuperModerator) {
        const link = `tournaments/${tournamentId}/users/${isHeadOrSuperModerator ? 'head_mod' : accountId}/moderator_chat`
        console.log('[SocketClient] Direct moderators data subscribed to ', link)
        this.directChatWithUserSubscriptions[tournamentId] = this.db
            .object(link).valueChanges().pipe(filter(v => !!v), takeUntil(this.destroy$))
            .subscribe(
                chatData => {
                    delete chatData[accountId]
                    console.log('[Socket client] Direct moderators data updated', chatData)
                    this.store.dispatch(
                        ChatboxActions.onModeratorDirectTournamentStateChange(
                            { data: chatData, tournamentId },
                        ),
                )
            },
        )
    }

    topicSubscribe( path: string ) {

        let tournamentNames
        let accountId
        let lastTournament

        this.store.select(
            TournamentDataSelectors.selectOpenedTournament,
        ).pipe(
            takeUntil(this.destroy$),
        ).subscribe( t => lastTournament = t)

        this.store.select(
            TournamentDataSelectors.selectAllTournamentsNames).pipe(takeUntil(this.destroy$))
        .subscribe( names => tournamentNames = names  )

        this.store.select(AccountSelectors.selectUserId).pipe(
            takeUntil(this.destroy$),
        ).subscribe( v => accountId = v)


        this.store.select(ChatboxSelectors.selectModeratorsDirectList)
        .pipe(
            takeUntil(this.destroy$),
        ).subscribe(
            val => this.moderatorsDirectReceivers = val,
        )



        this.client.subscribe(path, evt => {
            console.log('[SocketManager]: message received. ', evt)
            this.store.dispatch(onMessage({ evt }))


            const { body } = evt
            const parsed = JSON.parse(body)
            const eventType = parsed.eventType
            const { senderId, globalChatId, content } = parsed.body

            if(eventType === 'NEW_MESSAGE') {
                const realSender = this.moderatorsDirectReceivers[globalChatId].participants.find(p => p.id === senderId)

                const {
                    userExtra: {
                        id, imageUrl = '', nickName = '', firstName = '', lastName = '',
                    },
                } = realSender

                const ajaxSnackData: AjaxSnackData = {
                    imgUrl: imageUrl,
                    name: `${firstName}${lastName && ' '+ lastName }`,
                    tournamentName: 'Direct message',
                    text: content,
                    actionData: {
                        chatId: globalChatId,
                        chatType: ChatboxTabs.TM,
                        tournamentId: (866).toString(),
                    },
                }

                const config: MatSnackBarConfig = {
                    horizontalPosition: 'start',
                    duration: 3000,
                    panelClass: ['ajax-snackbar', 'snack-success'],
                    data: ajaxSnackData,
                }

                if ( accountId.toString() !== id.toString() ) {
                    this.snack.openFromComponent( AjaxSnackComponent, config )
                }
            }




            this.onMessageReceived(path, evt)
        }, {id: path})
    }

    subscribeToSharedModeratorsRoom( tournamentId ) {

        const link = `/${tournamentId}/moderator-chat/CREATE/`
        this.sharedModeratorsRoomSubscriptions[tournamentId] = this.client.subscribe(link, ({ body }) => {
            const message = JSON.parse(body)
            this.store.dispatch(ChatboxActions.onTmMessage({message, tournamentId}))
        })
    }


    // TODO: move to own service
    openSnack(chatType: ChatboxTabs, user, text, chatId, tournamentId?, tournamentName?) {

        const {
            id, imageUrl = '', nickName = '', firstName = '', lastName = '',
        } = user

        const data: AjaxSnackData = {
            imgUrl: imageUrl,
            name: `${firstName}${lastName && ' ' + lastName}`,
            tournamentName: tournamentName || tournamentId,
            text,
            actionData: {
                chatId,
                chatType,
                tournamentId: tournamentId.toString(),
            },
        }
        const config: MatSnackBarConfig = {
            horizontalPosition: 'start',
            duration: 3000,
            panelClass: ['ajax-snackbar', 'snack-success'],
            data,
        }
        this.snack.openFromComponent(AjaxSnackComponent, config)
    }

    subscribeToChatInitiation( userId: string ) {
        const link = `/chat/user-meta/${userId}`
        this.chatInitiationSubscription = this.client.subscribe(link, ({ body }) => {
            const message = JSON.parse(body)
            this.store.dispatch(ChatboxActions.fetchModeratorChatList())
        })
    }

    reportsSubscribe( tournamentId: string ) {
        this.reportsSubscriptions[tournamentId] = this.db.object(`tournaments_extra/${tournamentId}/support_messages`).valueChanges()
            .pipe(filter(x => !!x === true))
            .subscribe((value: RawReportsChain) => {

                this.store.dispatch(ChatboxActions.loadReportsChatSuccess({ data: value, tournamentId: tournamentId.toString() }))
                this.store.dispatch(loadUnreadInfo())

            })
    }

    disconnect(cb) {
        this.client.disconnect(cb)
    }

    _send(endpoint, message) {
        console.log('calling logout api via web socket')
        this.stompClient.send(endpoint, {}, JSON.stringify(message))
    }

    private onMessageReceived(name: string, message: any) {
        this.recieveDataSubject.next({ name, data: message })
    }

    clearMessages() {
        this.recieveDataSubject.next()
    }

    onMessage(): Observable<any> {
        return this.recieveDataSubject.asObservable()
    }

    ngOnDestroy() {
        this.destroy$.next(true)
    }

}
