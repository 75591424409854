import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '../../reducers/index'
import { Observable } from 'rxjs'
import { selectNavStages, selectNavRooms, selectTournamentId, selectMyRoomsIds } from '../timeline-feature.selectors'
import {
  toggleNavStageItem,
  toggleNavRoomItem,
  clickMyRoomsOnly,
  setMyRoomsIds,
  clearMyRoomsOnly
} from '../timeline-feature.actions'
import { selectAccountState, selectIsHeadOrSuperModerator } from '../../auth/account.selectors'
import { AccountService } from '../../auth/account.service'
import { RoomsHttpService } from '../../rooms/rooms-http.service'
import { takeUntil, withLatestFrom } from 'rxjs/operators'
import { ITourMatch } from '../../shared/model/tour-match.model';
import { TourRoom } from '../../shared/model/tour-room';

@Component({
  selector: 'app-tournament-matches-filter',
  templateUrl: './tournament-matches-filter.component.html',
  styleUrls: ['./tournament-matches-filter.component.scss'],
})
export class TournamentMatchesFilterComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private accountService: AccountService,
    private roomHttpService: RoomsHttpService,
  ) { }



  showOnlyMyRoomsButton = false
  showOnlyMyRooms = false

  selectStageActive
  selectRoomActive

  navStagesState: Observable<NavStagesState>
  navRoomsState: Observable<NavRoomsState>

  ngOnInit() {
    this.navStagesState = this.store.select(selectNavStages)
    this.navRoomsState = this.store.select(selectNavRooms)
    this.store.select(selectIsHeadOrSuperModerator).pipe(
      withLatestFrom(this.store.select(selectTournamentId)),
    ).subscribe(([isHeadOrSuperModerator, tournamentId]) => {
        this.showOnlyMyRoomsButton = isHeadOrSuperModerator
        if (isHeadOrSuperModerator) {
          this.roomHttpService.getMyRooms(tournamentId).subscribe((myRooms: []) => {
            this.store.dispatch(setMyRoomsIds({myRoomsIds: myRooms.map((myRoom: TourRoom) => myRoom.id)}))
          })
        }
      },
    )
  }

  onApply() {
    this.selectRoomActive = false
    this.selectStageActive = false
  }

  showMyRooms() {
    this.showOnlyMyRooms = !this.showOnlyMyRooms
    if (this.showOnlyMyRooms) {
      this.store.dispatch(clickMyRoomsOnly())
    } else {
      this.store.dispatch(clearMyRoomsOnly())
    }
  }

  roomsClickHandler(room: NavRoom) {
    this.store.dispatch(
      toggleNavRoomItem( { value: room.id } ),
    )
  }

  getRoomName(name: string, index: string): string {
    return name.startsWith('[')
      ? name
      : `[${index}] ${name}`
  }

  stagesClickHandler(stage: string): void {
    this.store.dispatch(toggleNavStageItem( { value: stage }))
  }

  roomsMouseOutHandler() {
    this.selectRoomActive = false
  }

  stagesMouseOutHandler() {
    this.selectStageActive = false
  }

}

export interface NavRoom {
  disabled: boolean
  checked: boolean
  value: string
  label: string
  stage: string
  id: string
  tourMatches?: any
  stageIndex: string
  // tournamentId:
}

export interface NavStage {
    label: string
    value: string
    checked: boolean
}

export interface NavStagesState {
  [key: string]: NavStage
}

export interface NavRoomsState {
  [key: string]: NavRoom
}
