import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Observable } from 'rxjs'
import { AppConstants } from 'src/app/app.constants'

@Injectable({
  providedIn: 'root',
})
export class TournamentHttpService {


  fetchTournaments(page = 0, size = 100, name = ''): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      AppConstants.SERVER_API_URL +
      '/api/tournaments?name=' + name + '&page=' + page + '&size=' + size + '&sort=id,desc',
      { observe: 'body' })
  }

  fetchModerators( page, size, name = '') {
    return this.http.post(
      `${AppConstants.SERVER_API_URL}/api/moderator-rest/getAllModerators?page=${page}&size=${size}&name=${name}`, {},
      { observe: 'response' })

  }

  initiateChat( moderatorId: string ): Observable<any> {
    return this.http.get<any>(`${AppConstants.SERVER_API_URL}/api/chat/direct/${moderatorId}`, { observe: 'body' })
  }

  subscribeToModeratorDirectChats() {

  }


  constructor(
    private http: HttpClient,
    protected db: AngularFireDatabase,
  ) { }
}
