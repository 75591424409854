import { createAction, props } from '@ngrx/store'
import { ChatboxTabs } from '../chatbox/models/ChatboxTabs.enum'
import { RawReportsChain } from '../reducers/chatbox.reducer'
import { MarkAsReadDTO } from '../unread/models/mark-as-read.model'


export const setOpen = createAction(
  '[Chatbox] Set open',
  props<{open: boolean}>(),
)

export const afterOpenTab = createAction(
  '[Chatbox] After open Tab',
  props<{ activeTab: ChatboxTabs, gamesMatch?: any }>(),
)

export const setModeratorActiveChat = createAction(
  '[Chatbox] Set Moderator Active Chat',
  props<{ chatId: string | undefined}>(),
)


export const markReadSharedRoom = createAction(
    '[Chatbox] Mark Read Shared Room',
)

export const fetchModeratorsList = createAction(
  '[Chatbox] Fetch Moderators List',
  props<{ query?: string, page?: number, size?: number }>(),
)

export const fetchModeratorsListSuccess = createAction(
  '[Chatbox] Fetch Moderators List Success',
  props<{ data: any }>(),
)

export const fetchModeratorsListFailure = createAction(
  '[Chatbox] Fetch Moderators List Failure',
  props<{ error: any }>(),
)



export const markReadModeratorMessage = createAction(
  '[Chatbox] Mark Read Moderator Message ',
  props<{ chatId: string }>(),
)

export const markReadModeratorMessageSuccess = createAction(
  '[Chatbox] Mark Read Moderator Message Success',
  props<{ data: any }>(),
)

export const markReadModeratorMessageFailure = createAction(
  '[Chatbox] Mark Read Moderator Message Failure',
  props<{ error: any }>(),
)


export const sendModeratorMessage = createAction(
  '[Chatbox] Send Moderator Message',
  props<{ content?: number }>(),
)

export const sendModeratorMessageSuccess = createAction(
  '[Chatbox] Send Moderator Message Success',
  props<{ data: any }>(),
)

export const sendModeratorMessageFailure = createAction(
  '[Chatbox] Send Moderator Message Failure',
  props<{ error: any }>(),
)

export const fetchModeratorMessages = createAction(
  '[Chatbox] Fetch Moderator Messages',
  props<{ chatId: string, page?: number, size?: number}>(),
)

export const fetchModeratorMessagesSuccess = createAction(
  '[Chatbox] Fetch Moderator Messages Success',
  props<{ data: any }>(),
)

export const fetchModeratorMessagesFailure = createAction(
  '[Chatbox] Fetch Moderator Messages Failure',
  props<{ error: any }>(),
)

export const fetchModeratorChatList = createAction(
  '[Chatbox] Fetch Moderator Chat List',
)

export const fetchModeratorChatListSuccess = createAction(
  '[Chatbox] Fetch Moderator Chat List Success',
  props<{ data: any }>(),
)

export const fetchModeratorChatListFailure = createAction(
  '[Chatbox] Fetch Moderator Chat List Failure',
  props<{ error: any }>(),
)

export const sendChatInGameSuccess = createAction(
  '[Chatbox] Send Chat In Game Success',
  props<{ res: any }>(),
)

export const sendChatInGameFailure = createAction(
  '[Chatbox] Send Chat In Game Failure',
  props<{ error: any }>(),
)



export const toggleOpen = createAction( '[Chatbox] Toggle open' )

export const setActiveTab = createAction( '[Chatbox] Set tab',  props<{activeTab: ChatboxTabs, gamesMatch?: any}>() )

export const setUserId = createAction( '[Chatbox] Set user id', props<{ userId: string }>())
export const setTournamentId = createAction( '[Chatbox] Set tournament id', props<{ tournamentId: string }>())

export const onTmMessage = createAction('[Chatbox] OnTmMessage', props<{ message: any, tournamentId: string } >())

export const fetchTmMessages = createAction('[Chatbox] Fetch TM Messages', props<{ tournamentId: string }>())
export const fetchTmMessagesSuccess = createAction('[Chatbox] Fetch TM Messages Success', props<{ data: any, tournamentId: string }>())
export const fetchTmMessagesFailure = createAction('[Chatbox] Fetch TM Messages Failure', props<{ error: any }>())

export const loadReportsChat = createAction(
  '[Chatbox] Load Reports Chat',
  props<{ tournamentId: number }>(),
)

export const loadReportsChatSuccess = createAction(
  '[Chatbox] Load Reports Chat Success',
  props<{ data: RawReportsChain, tournamentId: string }>(),
)

export const loadReportsChatFailure = createAction(
  '[Chatbox] Load Reports Chat Failure',
  props<{ error: any }>(),
)

export const reportsSetActiveId = createAction(
  '[Chatbox] Set reports active Id',
  props<{chatId: string}>(),
)

export const onModeratorDirectTournamentStateChange = createAction(
  '[Chatbox] On Moderator Direct Tournament message',
  props<{data: any, tournamentId?: string }>(),
)

export const onModeratorDirectTournamentSetActiveId = createAction(
  '[Chatbox] On Moderator Direct Tournament Set Active Id',
  props<{activeId: string, tournamentId?: string }>(),
)

export const sendModeratorDirectTournamentV2 = createAction(
  '[Chatbox] On Send Moderator Direct Tournament Message',
  props<{toSend: any}>(),
)

export const sendChatInGame = createAction(
  '[Chatbox] On Send Chat In Game Tournament Message',
  props<{
    tournamentId: any,
    tourMatchId: any,
    content: any,
    typeNumber: any,
  }> (),
)

export const sendModeratorDirectTournamentV2Success = createAction(
  '[Chatbox] On Send Moderator Direct Tournament Message Success',
  props<{data: any}>(),
)

export const sendModeratorDirectTournamentV2Failure = createAction(
  '[Chatbox] On Send Moderator Direct Tournament Message Failure',
  props<{error: any}>(),
)


export const markAsReadMessagesInfoByChatId = createAction(
  '[Unread] Mark As Read Messages Info By Chat Id',
  props<{chatId: string}>(),
  )

export const markAsReadSupportByChatId = createAction(
    '[Unread] Mark As Read Support by Chat Id',
    props<{chatId: string}>(),
)

export const markAsReadMessagesInfo = createAction(
    '[Unread] Mark As Read Messages Info',
    props<{dto: MarkAsReadDTO[]}>(),
)
export const markAsReadMessagesInfoSuccess = createAction(
    '[Unread] Mark As Read Messages Info Success',
    props<{data: any}>(),
)
export const markAsReadMessagesInfoFailure = createAction(
    '[Unread] Mark As Read Messages Info Failure',
    props<{error: any}>(),
)

export const updateUnreadReportsState = createAction(
  '[Unread] Update Unread Reports State',
)

export const updateUnreadState = createAction(
  '[Unread] Update Unread State',
)
