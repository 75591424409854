import { NgModule } from '@angular/core'
import { MinuteSecondsPipe } from './minutesSecond.pipe'
import { FilterTestPipe } from './filter-test.pipe'
import { ConsoleLogPipe } from './console-log.pipe';
import { FilterMyMatches } from './filter-my-matches.pipe';
import { FilterDirectChats } from './filter-direct-chats.pipe';

@NgModule({
    imports: [
    ],
    declarations: [
        MinuteSecondsPipe,
        FilterTestPipe,
        ConsoleLogPipe,
        FilterMyMatches,
        FilterDirectChats,
    ],
    exports: [
        MinuteSecondsPipe,
        FilterTestPipe,
        ConsoleLogPipe,
        FilterMyMatches,
        FilterDirectChats,
],
})
export class PipesModule { }
