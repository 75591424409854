import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTest'
})
export class FilterTestPipe implements PipeTransform {

  transform(matches: any, stages: any, rooms: any): any {
    const roomValues = Object.values(rooms)
    const filteredrooms = []
    roomValues.forEach((val: any) => {
      if (val.checked) {
        matches.forEach(match => {
          const el = val.tourMatches.find((i) => i.id === match.id)
          if (el) {
            filteredrooms.push(match)
          }
        })
      }
    })

    const newStageArr = []
    const keys = Object.keys(stages)
    keys.forEach((key) => {
      if (stages[key].checked) {
        newStageArr.push(stages[key].value)
      }
    })
    const filteredArr = []
    matches.forEach(match => {
      if (newStageArr.includes(match.stageIndex) && filteredrooms.find((v) => v === match)) {
        filteredArr.push(match)
      }
    })
    return filteredArr
  }

}
