import { Pipe, PipeTransform } from '@angular/core'
import { RoomStage } from '../../tournament-data/models/tournamentFull.interface';

@Pipe({
  name: 'roomStageOrder'
})
export class RoomStageOrderPipe implements PipeTransform {
  transform(roomStages: any, ...args: any[]): any {
    const stages = ['1', '2', '3', '4', '5', 'L16', 'QF', 'SF', 'F']
    return Object.values(roomStages).sort((a: RoomStage, b: RoomStage) => {
      return stages.indexOf(a.value) - stages.indexOf(b.value)
    })
  }
}



