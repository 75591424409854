import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef } from '@angular/material'

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
  onClickYes(): void {
    this.dialogRef.close(true)
  }

  onClickNo(): void {
    this.dialogRef.close(false)
  }
}
