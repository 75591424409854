import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { AppConstants } from 'src/app/app.constants'
import { AppState } from 'src/app/reducers'
import { TournamentsService } from 'src/app/services/tournament.service'
import { pushMismatchImage } from '../../timeline/timeline-feature.actions'
import { selectMatches } from '../../timeline/timeline-feature.selectors'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-result-modal',
  templateUrl: './edit-result-modal.component.html',
  styleUrls: ['./edit-result-modal.component.scss'],
})
export class EditResultModalComponent implements OnInit, OnDestroy {

  constructor(
    private tournamentsService: TournamentsService,
    public dialogRef: MatDialogRef<EditResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    ) {
  }

  inputScoreA = 0
  inputScoreB = 0
  matchId: any
  baseUrlImage: string = AppConstants.BASE_IMAGE_URL
  destroy$: Subject<boolean> = new Subject<boolean>()
  match
  expandFirst = false
  expandSecond = false
  // url = './assets/images/loader.gif'
  images = {
    nodeA: './assets/images/loader.gif',
    nodeB: './assets/images/loader.gif',
  }


  matchesSub$

  // dispatch() {
  //   const hardcodedEvt = {
  //     node: 'A',
  //     userExtraId: 1202,
  //     imageUrl: 'https://ajaxapp.s3.eu-north-1.amazonaws.com/1576018832204-avatar.jpeg',
  //     eventType: 'updateScoreMissMatchPhoto',
  //     matchId: '15110',
  //   }

  //   const { node, imageUrl, matchId } = hardcodedEvt
  //   this.store.dispatch(pushMismatchImage( { node, imageUrl, matchId } ))
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  ngOnInit() {
    this.matchId = this.data.matchId

    this.store.select(selectMatches)
    .pipe(takeUntil(this.destroy$))
    .subscribe( matches => {
      this.match = matches.filter(match => match.id === this.matchId)[0]
    })
    this.getPhoto('nodeA');
    this.getPhoto('nodeB');

  }

  onClose() {
    this.dialogRef.close()
  }

  onSaveResult() {
    const decideTheWinnerDTO = {
      tournamentId: this.data.tournamentId,
      tourMatchId: this.match.id,
      inputScoreA: this.inputScoreA,
      inputScoreB: this.inputScoreB,
    }

    this.tournamentsService.decideTheWinner(decideTheWinnerDTO)
      .subscribe((rs) => { this.dialogRef.close(true) },
       err => console.log(err),
      )
  }

  onMinus(value) {
    if (this[`inputScore${value}`] > 0) {
      this[`inputScore${value}`]--;      
    }
  }
  onPlus(value) {
    this[`inputScore${value}`]++;
  }

  expandPhoto(val) {
    this[val] = !this[val];
  }

  genClassFlag(isoCode: string) {
    return isoCode ? 'flag-icon-' + isoCode.toLowerCase() : ''
  }

  getPhoto(node) {
    let url = './assets/images/loader.gif';
    if (this.match && this.match[node] && this.match[node].inputPhoto) {
      fetch(this.match[node].inputPhoto)
        .then(res => {
          const img = document.createElement('img');
          img.src = this.match[node].inputPhoto;
          img.onload = () => {
            this.images[node] = this.match[node].inputPhoto;
          }
        })
    } else {
      setTimeout(() => {
        this.getPhoto(node);
      }, 2000);
    }
    return url;
  }

}
