import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { ActivatedRoute, Params } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, Subject, Subscription } from 'rxjs'
import { takeUntil, withLatestFrom } from 'rxjs/operators'
import { ChatboxTabs } from '../../chatbox/models/ChatboxTabs.enum'
import { AppState } from '../../reducers'
import { PushToAllModalComponent } from '../../shared/components/push-to-all-modal/push-to-all-modal.component'
import { WebSocketAPI } from '../../socket/web.socket'
import { Stage } from '../models/Stage'
import { TournamentScreenTab } from '../models/TournamentScreenTab.enum'
// tslint:disable-next-line:max-line-length
import {
  addTournamentEvent,
  clearStagesState,
  fetchNotifications,
  fetchParticipants,
  loadRooms,
  loadTourData,
  pushMismatchImage,
  setActiveTab,
  setNavStages,
  setTournamentId
} from '../timeline-feature.actions'
// tslint:disable-next-line:max-line-length
import { selectIsBracketsInited, selectMatches, selectNavRooms, selectNavStages, selectNoftificationsCount, selectNotifications, selectParticipants, selectRooms, selectSecsTillTournamentStart, selectStages, selectTournamentData, selectTournamentId, selectTournamentTab } from '../timeline-feature.selectors'
import { TimelineService } from '../timeline.service'
import { NavRoomsState } from '../tournament-matches-filter/tournament-matches-filter.component'
import { AjaxSnackComponent } from '../../shared/components/ajax-snack/ajax-snack.component';
import { UserInfoModalComponent } from '../../shared/components/user-info-modal/user-info-modal.component';

@Component({
  selector: 'app-ajax-timeline',
  templateUrl: './ajax-timeline.component.html',
  styleUrls: ['./ajax-timeline.component.scss'],
})

export class AjaxTimelineComponent implements OnInit, OnDestroy {
  @ViewChild('tabActive', { static: false }) allList: any;
  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private timelineService: TimelineService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>()

  ws: WebSocketAPI
  notificationsWS: WebSocketAPI
  tournamentEventsWS: WebSocketAPI


  chatboxTabs = ChatboxTabs

  tournamentIdSub$: Subscription
  id

  tournamentId: Observable<string>
  notifications: Observable<any>
  stages: Observable<Stage[]>
  rooms: Observable<any[]>
  tourMatches: Observable<any[]>
  isBracketsInited: Observable<boolean>

  activeTab: Observable<TournamentScreenTab>
  participants: Observable<any>

  tournamentData: Observable<any>

  notificationsLength: Observable<number>

  navStagesState: Observable<any>
  navRoomsState: Observable<NavRoomsState>
  height: 500
  secsToTournamentStart: Observable<number | undefined>

  tabClickHandler(tab: any): void {
    this.store.dispatch(setActiveTab({ tab }))
  }

  showSnack() {

    const config: MatSnackBarConfig = { horizontalPosition: 'start', duration: 2000  }
    this.snack.openFromComponent( AjaxSnackComponent,
      {...config, data: 'You got new message!'})
  }

  stagesClickHandler(val) {
    const stage = {
      ...val,
      checked: !val.checked,
    }
    this.store.dispatch( setNavStages( { stage }))
  }

  initParticipantsWS(tournamentId: string) {
    this.notificationsWS = new WebSocketAPI([
      { path: `/tournament/${tournamentId}/participant` },
    ])

    this.notificationsWS._connect()

    this.notificationsWS.getRecieveWebSocketData()
    .pipe(
      withLatestFrom(this.store.select(selectTournamentId)),
      takeUntil(this.destroy$),
    ).subscribe(([ socketEvt, id] ) => this.store.dispatch(fetchParticipants( { tournamentId: id})))

  }


  initPunishmentNotificationsWS(tournamentId: string) {
    this.notificationsWS = new WebSocketAPI([
      `/tournament/auto-punishment-notification/${tournamentId}/created`,
    ])

    this.notificationsWS._connect()

    this.notificationsWS.getRecieveWebSocketData()
    .pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.snack.open('New notification needs to be resolved')
        this.store.dispatch(fetchNotifications( ))
    } )
  }

  initTournamentEvents(tournamentId) {
    this.tournamentEventsWS = new WebSocketAPI([
      { path: `/tournament/${tournamentId}/events` },
    ])

    this.tournamentEventsWS._connect()

    this.tournamentEventsWS.getRecieveWebSocketData()
      .pipe(takeUntil(this.destroy$))
      .subscribe( evt => {

        const message = JSON.parse(evt.data.body)
        if (message.eventType === 'hasChat') {

        } else if (message.eventType === 'updateScoreMissMatchPhoto') {
          // const hardcoded = {
          //   node: 'B',
          //   userExtraId: 1202,
          //   imageUrl: 'https://ajaxapp.s3.eu-north-1.amazonaws.com/1576018832204-avatar.jpeg',
          //   eventType: 'updateScoreMissMatchPhoto',
          //   matchId: 15110,
          // }

          const { imageUrl, node, matchId } = message
          this.store.dispatch(pushMismatchImage({ imageUrl, node, matchId }))
        } else {
          this.store.dispatch(setTournamentId( { tournamentId } ))
        }

      })
  }

  initWebSocketAPi(tournamentId) {
    this.ws = new WebSocketAPI([
      {
        path: `/${tournamentId}/match-events/`,
      },
    ])

    this.ws._connect()

    this.ws.getRecieveWebSocketData()
    .pipe(takeUntil(this.destroy$))
    .subscribe(message => {

          const evt = JSON.parse(message.data.body)
          this.store.dispatch(addTournamentEvent({ evt }))


          console.log('^^^^^^^^^^^^^^^^^^^^\n\n\n match events:\n\n', evt)
    } )
  }

  ngOnInit() {
    this.secsToTournamentStart = this.store.select(selectSecsTillTournamentStart)

    this.activeTab = this.store.select(selectTournamentTab)
    this.tournamentId = this.store.select(selectTournamentId)
    this.stages = this.store.select(selectStages)
    this.rooms = this.store.select(selectRooms)
    this.tourMatches = this.store.select(selectMatches)
    this.participants = this.store.select(selectParticipants)
    this.isBracketsInited = this.store.select(selectIsBracketsInited)

    this.notifications = this.store.select(selectNotifications)
    this.notificationsLength = this.store.select(selectNoftificationsCount)

    this.navStagesState = this.store.select(selectNavStages)
    this.navRoomsState = this.store.select(selectNavRooms)

    this.tournamentData = this.store.select(selectTournamentData)
    this.store.select(selectTournamentId).pipe(takeUntil(this.destroy$)).subscribe(
      id => this.id = id,
    )

    this.route.params.subscribe((params: Params) => {
      if (params) {
        this.store.dispatch(clearStagesState())
        const tournamentId = params.id
        this.store.dispatch(setTournamentId({ tournamentId }))
        this.initWebSocketAPi(tournamentId)
        this.store.dispatch(fetchParticipants( { tournamentId }))
        this.store.dispatch(fetchNotifications())
        this.initTournamentEvents(tournamentId)
        this.initParticipantsWS(tournamentId)
      }
    })

    this.store.select(selectStages)
    .pipe(
      withLatestFrom(this.tournamentId),
      )
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      ( [data, tournamentId] ) => {
        if (!!data && !!tournamentId ) {
          const stages = data.map((stage: Stage) => stage.value)
          this.store.dispatch(loadRooms({ stages, tournamentId  }))
        }
      },
    )
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    // this.stagesSub$ && this.stagesSub$.unsubscribe()
  }

  onPauseNextRound() {
    this.timelineService.pauseNextRound(this.id).pipe(takeUntil(this.destroy$))
    .subscribe( data => data && this.store.dispatch(loadTourData({ tournamentId: this.id})) )
  }

  onResumeNextRound(id) {
    this.timelineService.resumeNextRound(this.id)
    .subscribe( data => data && this.store.dispatch(loadTourData({ tournamentId: this.id})) )
  }

  onNotifyAll() {
    const dialog = this.dialog.open(
      PushToAllModalComponent,
      { backdropClass: 'modal-blurred', data: { title: 'Notify all players'}, panelClass: 'modal-no-padding' },
    )



    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe( data => {
      if (data) {

        const {title, message} = data
        const msg = {
          moderatorId: 1026,
          title ,
          message,
          useForAll: false,
          tournamentId: this.id,
          userIds: null,
        }

        this.timelineService.notifyAllUsers(msg)
          .pipe(takeUntil(this.destroy$)).subscribe(res => console.log(res))
      }
    })
  }
}
