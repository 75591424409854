import { Observable } from "rxjs";
import { AppConstants } from "../app.constants";
import { HttpClient, HttpResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { ITutorials } from "../shared/model/tutorials.model";

type EntityResponseType = HttpResponse<ITutorials>;

@Injectable({ providedIn: "root" })
export class TutorialsService {
  constructor(private http: HttpClient) {}
  getTutorialList(
    page: number,
    size: number,
    name: string
  ): Observable<HttpResponse<any>> {
    console.log(
      "TutolistService getTutorials() ::: page " + page + "  size  " + size
    );
    return this.http.get(
      AppConstants.SERVER_API_URL +
        "/api/tutorials?name=" +
        name +
        "&page=" +
        page +
        "&size=" +
        size +
        "&sort=id,desc",
      { observe: "response" }
    );
  }
  getTutorialEdit(tutorialID: any): Observable<HttpResponse<any>> {
    console.log("TutolistService getTutorialsEdit() page :::" + tutorialID);
    return this.http.get(
      AppConstants.SERVER_API_URL + `/api/tutorials/${tutorialID}`,
      { observe: "response" }
    );
  }

  searchTutorials(
    name: string,
    page: number,
    size: number
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      AppConstants.SERVER_API_URL +
        "/api/tutorials?name=" +
        name +
        "&page=" +
        page +
        "&size" +
        size +
        "&sort=id,desc",
      { observe: "response" }
    );
  }
  create(tutorial: ITutorials): Observable<EntityResponseType> {
    return this.http
      .post<ITutorials>(
        AppConstants.SERVER_API_URL + "/api/tutorials",
        tutorial,
        { observe: "response" }
      )
      .pipe(map((res: EntityResponseType) => this.convertDataFromServer(res)));
  }
  update(tutorial: ITutorials): Observable<EntityResponseType> {
    console.log("update update update update update update");
    return this.http
      .put<ITutorials>(
        AppConstants.SERVER_API_URL + "/api/tutorials",
        tutorial,
        { observe: "response" }
      )
      .pipe(map((res: EntityResponseType) => this.convertDataFromServer(res)));
  }

  protected convertDataFromClient(tutorial: ITutorials): ITutorials {
    const copy: ITutorials = Object.assign({}, tutorial, {
      endAvailable:
        tutorial.endAvailable != null && tutorial.endAvailable.isValid()
          ? tutorial.endAvailable.toJSON
          : null,
    });
    return copy;
  }

  protected convertDataFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.endAvailable =
        res.body.endAvailable != null ? moment(res.body.endAvailable) : null;
    }
    return res;
  }
  deleteTutorials(tutorialID: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      AppConstants.SERVER_API_URL + `/api/tutorials/${tutorialID}`,
      { observe: "response" }
    );
  }
}
