import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { FormsModule } from '@angular/forms'
import { AjaxChatboxComponent } from './ajax-chatbox/ajax-chatbox.component'
import { ChatMessageItemComponent } from './dumb/chat-message-item/chat-message-item.component'
import { ModeratorsChatMessageItemComponent } from './dumb/moderators-chat-message-item/moderators-chat-message-item.component'
import { ReceiverListItemComponent } from './dumb/receiver-list-item/receiver-list-item.component'
import { TournamentModeratorsChatRoomComponent } from './dumb/tournament-moderators-chat-room/tournament-moderators-chat-room.component'
import { ModeratorDirectReceiverComponent } from './dumb/moderator-direct-receiver/moderator-direct-receiver.component'
import { ModeratorDirectMessageItemComponent } from './dumb/moderator-direct-message-item/moderator-direct-message-item.component'
import { PipesModule } from '../shared/pipes/pipes.modules'
import { RoomStageOrderPipe } from '../shared/pipes/room-stage-order.pipe'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    PipesModule,
  ],
  declarations: [
    AjaxChatboxComponent,
    ReceiverListItemComponent,
    ChatMessageItemComponent,
    ModeratorsChatMessageItemComponent,
    TournamentModeratorsChatRoomComponent,
    ModeratorDirectReceiverComponent,
    ModeratorDirectMessageItemComponent,
    RoomStageOrderPipe,
  ],
  exports: [
    AjaxChatboxComponent,
    RoomStageOrderPipe,
  ],
})
export class ChatboxModule { }
