import { flatten } from '@angular/compiler'
import { Action, createReducer, on } from '@ngrx/store'
import { ITournament } from '../shared/model/tournament.model'
import { Stage } from './models/Stage'
import { MatchEventType } from './models/TournamentEvent.interface'
import { TournamentScreenTab } from './models/TournamentScreenTab.enum'
import * as TimelineActions from './timeline-feature.actions'
import { NavRoom, NavRoomsState, NavStagesState } from './tournament-matches-filter/tournament-matches-filter.component'
import { clickMyRoomsOnly } from './timeline-feature.actions';

export const timelineFeatureFeatureKey = 'timelineFeature'

export interface TimelineState {

  tournamentId: string
  moderatorId: string
  stages: Stage[]
  tournamentData: ITournament
  rooms: any[]
  tourMatches: any[]
  participants: any // TODO: create interface for participants
  notifications: any

  activeTab: TournamentScreenTab

  navStagesState: NavStagesState
  navRoomsState: NavRoomsState

  myRoomsIds: []

  isNavSynced: boolean
}

export const initialState: TimelineState = {
  tournamentId: 'undefined',
  moderatorId: undefined,
  tournamentData: undefined,
  stages: [],
  rooms: [],
  tourMatches: [],
  notifications: {},


  navStagesState: {},
  navRoomsState: {},

  myRoomsIds: [],

  isNavSynced: undefined,
  participants: [],

  activeTab: TournamentScreenTab.Timeline,

}

const timelineFeatureReducer = createReducer(
  initialState,

  on(TimelineActions.pushMismatchImage, (state, { node, imageUrl, matchId } ) => {
    const newTourMatches = state.tourMatches.map( match => {
      if ( matchId.toString() === match.id.toString()) {
        const modifiedMatch = {
          ...match,
          ['node' + node]: {
            ...match['node'+ node],
            inputPhoto: imageUrl,
          },
        }

        return modifiedMatch
      } else {
        return  match
      }
    })

    return { ...state, tourMatches: newTourMatches }
  } ),

  on(TimelineActions.setModeratorId, (state, { moderatorId }) => ({ ...state, moderatorId })),
  on(TimelineActions.setTournamentId, (state, { tournamentId }) => ({ ...state, tournamentId })),


  on(TimelineActions.setActiveTab, ( state, { tab }) => ({ ...state, activeTab: tab })),


  on(TimelineActions.fetchNotificationsSuccess, (state, { data } ) => {

      const notifications = {}
      data.map(item => notifications[item.id] = item )

      return { ...state, notifications }
    }),

  on(TimelineActions.fetchParticipantsSuccess, ( state, action ) => {
    const participants = {}
    action.data.map(participant => participants[participant.id] = participant )
    return {
      ...state,
      participants,
    }
  }),
  on(TimelineActions.addTournamentEvent, (state, action) => {

    const { tourMatches } = state

    const newMatches = tourMatches.map(tourMatch => {

      if (action.evt.tourMatchId === tourMatch.id) {

        return {
          ...tourMatch,
          events: (action.evt.event.type === MatchEventType.RESET_MATCH) ? [] : [...tourMatch.events, action.evt.event],
        }
      }
      return tourMatch
    })

    return {
      ...state,
      tourMatches: newMatches,
    }
  }),


  on(TimelineActions.clearStagesState, (state) => {
    const navStagesState: NavStagesState = {}
    return { ...state, navStagesState }
  }),
  /**
   *  Loads stage data and creates navigation state
   */

  on(TimelineActions.loadStagesSuccess, (state, { data }) => {

    const stagesIsSelectedBefore = Object.keys(state.navStagesState).length > 0

    const stages = data.map(value => ({
      label: getStageLabel(value),
      value,
    }))

    const navStagesState: NavStagesState = {}

    data.map(value => navStagesState[value] = {
        label: getStageLabel(value),
        value,
        checked: stagesIsSelectedBefore ? state.navStagesState[value].checked : true,
      },
    )

    return { ...state, stages, navStagesState }
  }),

  on(TimelineActions.toggleNavStageItem,
    ( state, { value } ) => {

      // actual value of updated item
      const checked = !state.navStagesState[value].checked
      const changed = {
        ...state.navStagesState[value],
        checked,
      }

      const navStagesState = {
        ...state.navStagesState,
        [value]: changed,
      }



      return {
        ...state,
        navStagesState,
      }
    }),

  on(TimelineActions.setMyRoomsIds, (state, { myRoomsIds }) => {
    return {
      ...state,
      myRoomsIds,
    }
  }),

  on(TimelineActions.clickMyRoomsOnly, (state) => {
    let newNavRoomsState = {}
    if (state.myRoomsIds.length) {
      Object.keys(state.navRoomsState).forEach(roomId => {
        newNavRoomsState[roomId] = {
          ...state.navRoomsState[roomId],
          checked: state.myRoomsIds.includes(parseInt(roomId, 10)),
        }
      })
    } else {
      newNavRoomsState = state.navRoomsState
    }

    return {
      ...state,
      navRoomsState: newNavRoomsState,
    }
    }),

  on(TimelineActions.clearMyRoomsOnly, (state) => {
    const newNavRoomsState = {}
    Object.keys(state.navRoomsState).forEach(roomId => {
      newNavRoomsState[roomId] = {
        ...state.navRoomsState[roomId],
        checked: true,
      }
    })


    return {
      ...state,
      navRoomsState: newNavRoomsState,
    }
  }),

  on(TimelineActions.toggleNavRoomItem, (state, { value } ) => {
    const checked = !state.navRoomsState[value].checked
    const changed = {
      ...state.navRoomsState[value],
      checked,
    }

    const navRoomsState = {
      ...state.navRoomsState,
      [value]: changed,
    }

    return {
      ...state,
      navRoomsState,
    }
  }),

  on(TimelineActions.setNavStages,
    (state, action) => {
      console.log(action)

      return state
    },
  ),


  on(TimelineActions.loadRoomsSuccess, ( state, { data } ) => {
    const navRoomsState = {}
    data.map( ({ stageIndex, name, id, tourMatches }) => navRoomsState[ id ] = {
      stageIndex,
      name,
      id,
      tourMatches,
      checked: true,
      disabled: false,
    })

    return ({
    ...state,
    rooms: data,
    navRoomsState,
    tourMatches: flatten(
      data.map(
        room => room.tourMatches.map(match => {
          return {
            ...match,
            roomId: room.id,
          }
        }),
      ),
    ),
  })
  },

  ),


  on(TimelineActions.loadTourDataSuccess, (state, action) => ({ ...state, tournamentData: action.data })),
)

export function reducer(state: TimelineState | undefined, action: Action) {
  return timelineFeatureReducer(state, action)
}


export const getStageLabel = value => {
  switch (value) {
    case 'F':
      return 'Final'
    case 'SF':
      return 'Semi Final'
    case 'QF':
      return 'Quarter Final'
    case 'L16':
      return 'R 16'
    case '5':
      return 'R 32'
    case '4':
      return 'R 64'
    case '3':
      return 'R 128'
    case '2':
      return 'R 256'
    case '1':
      return 'R 512'
    default:
      return value
  }
}
