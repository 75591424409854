import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Inject, OnInit } from '@angular/core'
// import { FormBuilder } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { AppState } from '../../../../reducers/index'
import { selectModeratorsDirectList } from '../../../../selectors/chatbox.selectors'
import * as DataActions from '../../../../tournament-data/store/tournament-data.actions'
import * as DataSelectors from '../../../../tournament-data/store/tournament-data.selectors'
import { PaginatorState } from '../../../model/paginatorState.model'
import * as AccountSelectors from '../../../../auth/account.selectors';

@Component({
  selector: 'app-moderator-find-modal',
  templateUrl: './moderator-find-modal.component.html',
  styleUrls: ['./moderator-find-modal.component.scss'],
    animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ],
})
export class ModeratorFindModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModeratorFindModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
  ) { }

  searchString
  moderators$
  moderatorsListLength$
  moderatorsPaginatorState$: Observable<PaginatorState>
  withFlags: any

  onSearch(event) {
    this.store.dispatch(DataActions.setModeratorsSearch( { name: this.searchString} ))
  }

  onPaginatorState(evt) {
    const { pageSize, pageIndex } = evt

    this.store.dispatch(
      DataActions.setModeratorsPaginator({
        state: {
          size: pageSize,
          page: pageIndex,
        },
      }),
    )
  }

  getAnotherParticipant(participants, currentParticipant) {
    participants = participants || []
    return participants.find((participant) => currentParticipant.userExtra.id !== participant.userExtra.id)
  }

  ngOnInit() {
    this.store.dispatch(DataActions.fetchModerators())
    this.moderators$ = this.store.select(DataSelectors.selectModerators).pipe(
      withLatestFrom(this.store.select(selectModeratorsDirectList), this.store.select(AccountSelectors.selectUserId)),
      map(([moderators, filter, userId]) => {
        const ext = Object.values(filter).map(
          ({ participants, currentParticipant}) => this.getAnotherParticipant(participants, currentParticipant).userExtra.id,
        )

        const withFlags = {}
        for (const [key, moderator] of Object.entries(moderators)) {
          if (moderator.id === userId) {
            continue
          }

          if ( ext.includes(moderator.id) ) {
            withFlags[key] = {...moderators[key], chatInitiated: true }
          } else {
            withFlags[key] = {...moderators[key], chatInitiated: false }
          }
        }

        this.withFlags = withFlags
      }),
    ).subscribe(v => v)

    this.moderatorsListLength$ = this.store.select(DataSelectors.selectModeratorsTotalCount)
    this.moderatorsPaginatorState$ = this.store.select(DataSelectors.selectModeratorsPaginator)
  }

  onAddButton(evt) {
    this.store.dispatch( DataActions.initiateChat( { userId: evt }) )
    this.dialogRef.close(false)
  }

  onOpenChat(evt) {
    this.dialogRef.close(false)
  }

  onCancel() {
    this.dialogRef.close(false)
  }

}
