import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstants } from "../../app.constants";
@Injectable({
  providedIn: "root",
})
export class RulesService {
  constructor(private http: HttpClient) {}

  getRules(page: number, size: number): Observable<HttpResponse<any>> {
    return this.http.get(
      AppConstants.SERVER_API_URL +
        "/api/tournament-rule?page=" +
        page +
        "&size=" +
        size +
        "&sort=id,desc",
      { observe: "response" }
    );
  }

  getAllRules(): Observable<HttpResponse<any>> {
    return this.http.get(
      AppConstants.SERVER_API_URL +
        "/api/tournament-rule?page=" +
        0 +
        "&size=" +
        1000 +
        "&sort=id,desc",
      { observe: "response" }
    );
  }

  getRulesByKeyword(
    page: number,
    size: number,
    name: string
  ): Observable<HttpResponse<any>> {
    console.log(
      "TutolistService getTutorials() ::: page " + page + "  size  " + size
    );
    return this.http.get(
      AppConstants.SERVER_API_URL +
        "/api/tournament-rule/by-keyword?keyword=" +
        name +
        "&page=" +
        page +
        "&size=" +
        size +
        "&sort=id,desc",
      { observe: "response" }
    );
  }

  deleteRule(ruleID: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      AppConstants.SERVER_API_URL +
        `/api/tournament-rule/?tournamentRuleId=${ruleID}`,
      { observe: "response" }
    );
  }

  postRule(rule: any): Observable<HttpResponse<any>> {
    return this.http.post(
      AppConstants.SERVER_API_URL + "/api/tournament-rule",
      rule,
      { observe: "response" }
    );
  }

  updateRule(rule: any): Observable<HttpResponse<any>> {
    return this.http.post(
      AppConstants.SERVER_API_URL + "/api/tournament-rule/update",
      rule,
      { observe: "response" }
    );
  }
}
