import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filterDirectChats',
})
export class FilterDirectChats implements PipeTransform {

  transform(chats: any, toModeratorId: any, showOnlyFromMyRooms = false): any {
    return showOnlyFromMyRooms ?  chats.filter(chat => chat.moderatorId === toModeratorId) : chats
  }

}
